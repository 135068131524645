export default function getVideoSources(sources) {
    return (sources || [])
        .filter(({ mime = '' }) => mime.match(/^video\/(mp4|webm|mpeg)/) !== null)
        .sort(({ size: a = '' }, { size: b = '' }) => {
            if (a === b) {
                return 0;
            }
            return a > b ? 1 : -1;
        });
}
