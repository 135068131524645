import { useTracking } from '@folklore/tracking';
import { useQuery, keepPreviousData as previousPlaceholder } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';

import { useApi } from '../contexts/ApiContext';

export function prefetchSearch(queryClient, api, query, opts = null) {
    const { count = 20 } = opts || {};
    return queryClient.prefetchQuery({
        queryKey: ['search', query, count],
        queryFn: ({ queryKey: [, searchParam, countParam], signal }) =>
            api.search.get(
                searchParam,
                {
                    count: countParam,
                },
                { signal },
            ),
    });
}

export default function useSearch(search, opts = null) {
    const {
        minLength = 3,
        debounce = 500,
        keepPreviousData = true,
        count = 20,
        enabled = true,
        ...queryOpts
    } = opts || {};
    const api = useApi();
    const tracking = useTracking();
    const [searchQuery, setSearchQuery] = useState(
        search !== null && search.length >= minLength ? search : null,
    );
    useEffect(() => {
        if (search !== null && search.length < minLength) {
            return () => {};
        }
        if (search === null || searchQuery === null) {
            setSearchQuery(search);
            return () => {};
        }
        const timeout = setTimeout(() => {
            setSearchQuery(search);
        }, debounce);
        return () => {
            clearTimeout(timeout);
        };
    }, [search, minLength, debounce, tracking]);
    const {
        data: items = null,
        refetch,
        ...queryResult
    } = useQuery({
        queryKey: ['search', searchQuery, count],
        queryFn: ({ queryKey: [, searchParam, countParam], signal }) =>
            api.search.get(
                searchParam,
                {
                    count: countParam,
                },
                { signal },
            ),
        enabled: enabled && searchQuery !== null,
        suspense: false,
        placeholderData: keepPreviousData ? previousPlaceholder : null,
        ...queryOpts,
    });

    useEffect(() => {
        if (searchQuery !== null && items !== null) {
            tracking.trackSearch(searchQuery);
        }
    }, [items]);

    const update = useCallback(() => {
        if (search !== searchQuery) {
            setSearchQuery(search);
        } else {
            refetch();
        }
    }, [search, searchQuery, refetch, tracking, setSearchQuery]);

    return {
        items,
        refetch,
        update,
        searchQuery,
        ...queryResult,
    };
}
