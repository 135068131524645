import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import pick from 'lodash/pick';
import queryString from 'query-string';

import { useApi } from '../contexts/ApiContext';
import { useUpdateConsent } from '../contexts/ConsentContext';

// import { updateConsent } from '../contexts/ConsentContext';

function getAuthQuery() {
    return pick(
        queryString.parse(typeof window !== 'undefined' ? window.location.search : '') || {},
        ['_cio_id', '_cio_did'],
    );
}

export function updateAuthUser(queryClient, user) {
    const data = queryClient.getQueryData(['auth']);
    queryClient.setQueryData(['auth'], { ...data, user });
}

export function updateAuthAudiences(queryClient, audiences) {
    const data = queryClient.getQueryData(['auth']);
    queryClient.setQueryData(['auth'], { ...data, audiences });
}

export function prefetchAuthUser(queryClient, api) {
    return queryClient.prefetchQuery({
        queryKey: ['auth'],
        queryFn: ({ signal }) => api.auth.check(getAuthQuery(), { signal }),
    });
}

export function useAuthCheck(opts = null) {
    const api = useApi();
    const { data = null, ...queryResult } = useQuery({
        queryKey: ['auth'],
        queryFn: ({ signal }) => api.auth.check(getAuthQuery(), { signal }),
        suspense: false,
        ...opts,
    });

    return {
        user: null,
        ...data,
        ...queryResult,
    };
}

export function useAuthSendToken() {
    const api = useApi();
    const { mutate, mutateAsync, ...mutationResult } = useMutation({
        mutationFn: (data) => api.auth.sendToken(data),
    });

    return {
        sendToken: mutate,
        sendTokenAsync: mutateAsync,
        ...mutationResult,
    };
}

export function useAuthLogin() {
    const api = useApi();
    const queryClient = useQueryClient();
    const { mutate, mutateAsync, ...mutationResult } = useMutation({
        mutationFn: (data) => api.auth.login(data),
        onSuccess: (response) => {
            queryClient.setQueryData(['auth'], response);
        },
    });

    return {
        login: mutate,
        loginAsync: mutateAsync,
        ...mutationResult,
    };
}

export function useAuthProviderLogin(provider) {
    const api = useApi();
    const queryClient = useQueryClient();
    const { mutate, mutateAsync, ...mutationResult } = useMutation({
        mutationFn: (data) => api.auth.providerLogin(provider, data),
        onSuccess: (response) => {
            queryClient.setQueryData(['auth'], response);
        },
    });

    return {
        login: mutate,
        loginAsync: mutateAsync,
        ...mutationResult,
    };
}

export function useAuthIdentify() {
    const api = useApi();
    const queryClient = useQueryClient();
    const updateConsent = useUpdateConsent();
    const { mutate, mutateAsync, ...mutationResult } = useMutation({
        mutationFn: (data) => api.auth.identify(data),
        onSuccess: (response) => {
            queryClient.setQueryData(['auth'], response);
            const { user = null } = response || {};
            if (user !== null) {
                updateConsent(true);
            }
        },
    });

    return {
        identify: mutate,
        identifyAsync: mutateAsync,
        ...mutationResult,
    };
}

export function useAuthLogout() {
    const api = useApi();
    const queryClient = useQueryClient();
    const { mutate, mutateAsync, ...mutationResult } = useMutation({
        mutationFn: () => api.auth.logout(),
        onSuccess: (response) => {
            queryClient.setQueryData(['auth'], response);
        },
    });

    return {
        logout: mutate,
        logoutAsync: mutateAsync,
        ...mutationResult,
    };
}
