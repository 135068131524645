/* eslint-disable react/no-danger */
import { useIsVisible } from '@folklore/hooks';
import { loadTikTok, loadInstagram, loadTwitter } from '@folklore/services';
import { Widget } from '@niche-js/core/components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef, useMemo, useEffect } from 'react';

import { useIsCapture } from '../../contexts/LayoutContext';

import styles from '../../styles/blocks/embed-block.module.css';

const propTypes = {
    embed: PropTypes.shape({
        url: PropTypes.string,
        provider: PropTypes.string,
        iframeUrl: PropTypes.string,
        html: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
    }),
    loading: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    embed: null,
    loading: 'lazy',
    className: null,
};

function Embed({ embed, loading, className }) {
    const refEmbed = useRef();
    const { provider, iframeUrl = null, html = null, width, height } = embed || {};
    const finalFrameUrl = useMemo(() => iframeUrl || null, [iframeUrl]);
    const iframeContainerRef = useRef(null);
    const isCapture = useIsCapture();
    const isLazyLoading = loading === 'lazy' && !isCapture;
    const { ref: visibleRef, visible: isVisible } = useIsVisible({
        rootMargin: '200px',
        persist: true,
        disabled: !isLazyLoading,
    });

    const shouldLoad = !isLazyLoading || isVisible || provider === 'instagram';
    // const shouldLoad = true;

    useEffect(() => {
        const { current: element = null } = iframeContainerRef;
        if (provider === 'twitter' && shouldLoad) {
            loadTwitter().then((twttr) => {
                if (element !== null) {
                    twttr.widgets.load(element);
                }
            });
        } else if (provider === 'instagram' && shouldLoad) {
            loadInstagram().then(() => {
                if (typeof window !== 'undefined' && typeof window.instgrm !== 'undefined') {
                    window.instgrm.Embeds.process();
                }
            });
        } else if (provider === 'tiktok' && shouldLoad) {
            loadTikTok();
        }
    }, [provider, shouldLoad]);

    return (
        <Widget
            ref={visibleRef}
            className={classNames([styles.container, { [className]: className !== null }])}
        >
            {finalFrameUrl !== null ? (
                <div className={styles.iframeContainer} ref={iframeContainerRef}>
                    {shouldLoad ? (
                        <iframe
                            src={finalFrameUrl}
                            ref={refEmbed}
                            className={classNames([styles.iframe, `iframe-${provider}`])}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embed"
                            width={width || '100%'}
                            height={height || '100%'}
                        />
                    ) : null}
                </div>
            ) : null}
            {finalFrameUrl === null && html !== null ? (
                <div
                    className={classNames([styles.iframeContainer])}
                    dangerouslySetInnerHTML={{ __html: html }}
                    ref={iframeContainerRef}
                />
            ) : null}
        </Widget>
    );
}

Embed.propTypes = propTypes;
Embed.defaultProps = defaultProps;

export default Embed;
