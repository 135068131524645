export const formats = [
    {
        id: 'instagram',
        label: 'Instagram',
        width: 1080,
        height: 1350,
    },
    {
        id: 'facebook',
        label: 'Facebook',
        width: 1200,
        height: 800,
    },
];

const variationsCreator = [
    ({ id, width, height } = {}) => ({
        id: `over-${id}`,
        format: id,
        label: 'Par dessus',
        theme: 'over-bottom-left',
        responsive: true,
        scale: width > height ? 1.5 : 2.8,
        withoutSurtitle: true,
        urbaniaLogoSize: width > height ? 0.2 : 0.5,
        // size: 'big',
    }),
    ({ id, width, height } = {}) => ({
        id: `box-${id}`,
        format: id,
        label: 'Dans une boîte',
        theme: 'box-bottom-left',
        responsive: true,
        scale: width > height ? 1.5 : 2.5,
        urbaniaLogoSize: width > height ? 0.2 : 0.5,
        // size: 'big',
    }),
];

export const defaultProps = {
    authorPrefix: 'Un texte de',
    theme: 'over-bottom-left',
    withoutAuthorBox: true,
    withoutDate: true,
    withoutAuthorImage: true,
    withUrbaniaLogo: true,
    textShadow: 0,
};

export const variations = formats.reduce(
    (allVariations, format) => [
        ...allVariations,
        ...variationsCreator.map((creator) => creator(format)),
    ],
    [],
);

export const utmSources = [
    {
        label: 'Journaliste',
        value: 'journaliste',
    },
    {
        label: 'Employés URBANIA',
        value: 'employes_urbania',
    },
    {
        label: 'RP',
        value: 'rp',
    },
    {
        label: 'Contact Direct',
        value: 'contact_direct',
    },
    {
        label: 'Partenariat Rémunéré',
        value: 'partenariat_remunere',
    },
    {
        label: 'ManyChat',
        value: 'manychat',
    },
];

export const utmMediums = [
    {
        label: 'Partage',
        value: 'partage',
    },
];
