/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function DocumentClassIcon({ className }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="56"
            viewBox="0 0 44 56"
            className={className}
        >
            <filter
                id="drop-shadow-1"
                x="-15.5"
                y="-15.7"
                width="75"
                height="87"
                filterUnits="userSpaceOnUse"
            >
                <feOffset dx="0" dy="0" />
                <feGaussianBlur result="blur" stdDeviation="5" />
                <feFlood floodColor="#000" floodOpacity=".4" />
                <feComposite in2="blur" operator="in" />
                <feComposite in="SourceGraphic" />
            </filter>
            <g filter="url(#drop-shadow-1)">
                <g>
                    <path
                        fill="#edeefe"
                        d="M34.5,0H1.5C.7,0,0,.6,0,1.4v53.1c0,.8.7,1.4,1.5,1.4h41.1c.8,0,1.5-.6,1.5-1.4V9S34.5,0,34.5,0Z"
                    />
                    <path fill="#5e79ff" d="M35.4,9h8.6S34.5,0,34.5,0v8.1c0,.5.4.9.9.9Z" />
                </g>
                <g>
                    <g>
                        <path
                            fill="#222"
                            d="M14.2,46s0,0-.1,0l-2.6-2.6c0,0,0-.2,0-.3s.2,0,.3,0l2.6,2.6c0,0,0,.2,0,.3s0,0-.1,0Z"
                        />
                        <path
                            fill="#222"
                            d="M11.6,46s0,0-.1,0c0,0,0-.2,0-.3l2.6-2.6c0,0,.2,0,.3,0s0,.2,0,.3l-2.6,2.6s0,0-.1,0Z"
                        />
                    </g>
                    <path
                        fill="#222"
                        d="M32.4,48.2H11.6c-.1,0-.2,0-.2-.2s0-.2.2-.2h20.9c.1,0,.2,0,.2.2s0,.2-.2.2Z"
                    />
                </g>
                <path
                    fill="#45a"
                    d="M17.6,50.8c-.1,0-.2,0-.4,0-.4,0-.9-.5-1.1-1.1-.3-.6-.5-1.9,1-3.6.2-.3.5-.5.7-.7-1.2-.9-2.9-2.5-2.9-4.8s2.2-2.9,2.8-2.9c1.5,0,3.6,1.5,3.8,5.7.4,0,.7,0,1.1,0,.5,0,1.1,0,1.3.5.2.3.2.7,0,1.2-.1.4,0,.6,0,.6,0,0,.3,0,.9-.6,1.1-1,2-1.6,2.6-1.4.3.1.5.4.6.8.1.9.3,1,.4,1.1.1,0,.3,0,.4,0l.4.6c-.4.2-.8.3-1.1.1-.4-.2-.7-.7-.8-1.6,0-.2,0-.2,0-.3,0,0-.4,0-1.8,1.3-1,.9-1.5.8-1.8.6-.4-.2-.5-.8-.3-1.4.1-.4,0-.5,0-.6,0,0-.2-.2-.7-.2s-.7,0-1.1.1c0,1.1-.3,4.4-2,5.9-.6.5-1.2.7-2,.7ZM18.5,45.7c-.3.2-.5.5-.8.8-1.2,1.4-1.1,2.3-.9,2.8.2.5.5.7.5.7.7,0,1.2-.1,1.7-.5,1.3-1.1,1.7-3.6,1.8-5.2-.5.2-1.1.5-1.7.9.2.1.4.2.4.2l-.3.7s-.3-.1-.7-.4ZM17.8,38.3c-.8,0-2.1.3-2.1,2.2s1.6,3.5,2.8,4.3c.8-.7,1.6-1.1,2.4-1.3,0-3.8-1.9-5.2-3.1-5.2Z"
                />
                <rect fill="#5e79ff" x="7.3" y="13.4" width="29.3" height="2.2" />
                <rect fill="#5e79ff" x="7.3" y="17.8" width="29.3" height="2.2" />
                <rect fill="#5e79ff" x="7.3" y="22.1" width="29.3" height="2.2" />
                <rect fill="#5e79ff" x="7.3" y="26.4" width="29.3" height="2.2" />
                <rect fill="#5e79ff" x="7.3" y="30.8" width="14.7" height="2.2" />
            </g>
        </svg>
    );
}

DocumentClassIcon.propTypes = propTypes;
DocumentClassIcon.defaultProps = defaultProps;

export default DocumentClassIcon;
