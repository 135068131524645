import { useTracking } from '@folklore/tracking';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { startTransition, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useUpdateConsent } from '../../contexts/ConsentContext';
import { usePopupsContext } from '../../contexts/PopupsContext';
import { useSite } from '../../contexts/SiteContext';
import BasicButton from '../buttons/BasicButton';
import IdentifyForm from '../forms/IdentifyForm';
import SubscriptionForm from '../forms/SubscriptionForm';
import LineSpacer from '../partials/LineSpacer';
import ModalDescription from '../typography/ModalDescription';
import ModalTitle from '../typography/ModalTitle';
import Dialog from './Dialog';
import Modal from './Modal';

import styles from '../../styles/popups/consent-modal.module.css';

const propTypes = {
    brand: PropTypes.string,
    promotion: PropTypes.shape({}),
    title: PropTypes.string,
    description: PropTypes.string,
    cookiesDescription: PropTypes.string,
    loginDescription: PropTypes.string,
    className: PropTypes.string,
    onClosed: PropTypes.func,
};

const defaultProps = {
    brand: null,
    promotion: null,
    title: null,
    description: null,
    cookiesDescription: null,
    loginDescription: null,
    className: null,
    onClosed: null,
};

function ConsentModal({
    brand,
    promotion,
    title: providedTitle,
    description: providedDescription,
    cookiesDescription: providedCookiesDescription,
    loginDescription: providedLoginDescription,
    className,
    onClosed,
}) {
    const { handle: siteHandle } = useSite();
    const {
        title = providedTitle,
        description = providedDescription,
        cookiesDescription = providedCookiesDescription,
        loginDescription = providedLoginDescription,
    } = promotion || {};
    const [opened, setOpened] = useState(true);
    const tracking = useTracking();
    const updateConsent = useUpdateConsent();
    const [fieldHasValue, setFieldHasValue] = useState(null);
    const { addMessage } = usePopupsContext();
    const isSubscription = true;
    const formRef = useRef(null);

    const consent = useCallback(
        (type) => {
            startTransition(() => {
                updateConsent(true);
                setOpened(false);
            });
            tracking.trackEventNow('Consent', 'given', type);
        },
        [updateConsent, setOpened, tracking],
    );

    const onClickAccept = useCallback(() => {
        if (fieldHasValue) {
            formRef.current.submit();
            return;
        }
        consent('accept');
    }, [fieldHasValue, consent]);

    const onIdentify = useCallback(
        ({ user = null, isNew = false }) => {
            consent('identify');
            addMessage('hi', {
                isNew,
                user,
            });
        },
        [consent, addMessage],
    );

    const onClickSso = useCallback(
        (type) => {
            updateConsent(true);
            tracking.trackEventNow('Consent', 'given', `sso_${type}`);
        },
        [tracking],
    );

    const onFormChange = useCallback(
        (newFormValue) => {
            const { field = null } = newFormValue || {};
            setFieldHasValue(field !== null && field.length > 0);
        },
        [setFieldHasValue],
    );

    useEffect(() => {
        tracking.trackEventNow('Consent', 'impression', 'modal');
    }, []);

    const onRequestClose = useCallback(() => {
        startTransition(() => {
            setOpened(false);
        });
    }, [setOpened]);

    const cookies = (
        <div className={classNames([styles.section, styles.cookies])}>
            {cookiesDescription !== null ? (
                <ModalDescription
                    tag="div"
                    html={cookiesDescription}
                    className={classNames([styles.description])}
                />
            ) : null}
            <BasicButton className={styles.button} onClick={onClickAccept}>
                <FormattedMessage
                    defaultMessage="Accepter les cookies et continuer"
                    description="Spacer label"
                />
            </BasicButton>
        </div>
    );

    const login = (
        <div className={classNames([styles.section, styles.login])}>
            {loginDescription !== null ? (
                <ModalDescription
                    tag="div"
                    html={loginDescription}
                    className={classNames([styles.description])}
                />
            ) : null}
            {isSubscription ? (
                <SubscriptionForm
                    ref={formRef}
                    source="consent-modal"
                    subscription={siteHandle}
                    subscribeButtonLabel="S’identifier"
                    via={['email', 'sms']}
                    ssoTheme="dark"
                    withoutEditContact
                    withoutMessages
                    className={styles.form}
                    onComplete={onIdentify}
                    onChange={onFormChange}
                    onClickSso={onClickSso}
                />
            ) : (
                <IdentifyForm
                    ref={formRef}
                    source="consent-modal"
                    className={styles.form}
                    onComplete={onIdentify}
                    onChange={onFormChange}
                    onClickSso={onClickSso}
                />
            )}
        </div>
    );

    const availableSections = {
        cookies,
        login,
    };

    const sections = siteHandle === 'france' ? ['cookies', 'login'] : ['login', 'cookies'];

    return (
        <Modal
            id="consent"
            opened={opened}
            withBackdrop
            withTransition
            withScrollLock
            onRequestClose={onRequestClose}
            className={classNames([
                styles.container,
                { [className]: className !== null, [styles[brand]]: brand !== null },
            ])}
            onClosed={onClosed}
        >
            <Dialog className={styles.dialog}>
                <div className={classNames([styles.section, styles.main])}>
                    <ModalTitle className={styles.title} html={title} />
                    <ModalDescription html={description} className={styles.description} />
                </div>
                {sections.map((section, index) => (
                    <>
                        {index > 0 ? (
                            <LineSpacer className={styles.spacer}>
                                <FormattedMessage defaultMessage="ou" description="Spacer label" />
                            </LineSpacer>
                        ) : null}
                        {availableSections[section]}
                    </>
                ))}
            </Dialog>
        </Modal>
    );
}

ConsentModal.propTypes = propTypes;
ConsentModal.defaultProps = defaultProps;

export default ConsentModal;
