/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/fields/text-field.module.css';

const propTypes = {
    type: PropTypes.oneOf(['text', 'email', 'tel']),
    name: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.string,
    errors: AppPropTypes.formErrors,
    placeholder: PropTypes.string,
    autoComplete: PropTypes.string,
    withoutAutocomplete: PropTypes.bool,
    withBorder: PropTypes.bool,
    required: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func,
    onInput: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onPointerUp: PropTypes.func,
    inputRef: AppPropTypes.ref,
};

const defaultProps = {
    type: 'text',
    name: null,
    id: null,
    value: null,
    errors: null,
    placeholder: null,
    autoComplete: null,
    withoutAutocomplete: false,
    withBorder: false,
    required: false,
    className: null,
    onChange: null,
    onInput: null,
    onFocus: null,
    onBlur: null,
    onPointerUp: null,
    inputRef: null,
};

function TextField({
    type,
    name,
    id,
    value,
    errors,
    placeholder,
    autoComplete,
    withoutAutocomplete,
    withBorder,
    required,
    className,
    onChange,
    onInput: onCustomInput,
    onFocus,
    onBlur,
    onPointerUp,
    inputRef,
}) {
    const internalRef = useRef(null);

    const onInputChange = useCallback(
        (e) => {
            if (errors !== null) {
                internalRef.current.setCustomValidity('');
                // internalRef.current.reportValidity();
            }
            const newValue = e.currentTarget.value !== '' ? e.currentTarget.value : null;
            if (onChange !== null) {
                onChange(newValue, e);
            }
        },
        [onChange, errors],
    );

    const onInput = useCallback(
        (e) => {
            if (errors !== null) {
                internalRef.current.setCustomValidity('');
            }
            if (onCustomInput !== null) {
                onCustomInput(e);
            }
        },
        [onCustomInput, errors],
    );

    useEffect(() => {
        internalRef.current.setCustomValidity(
            errors !== null && errors.length > 0 ? errors[0] : '',
        );
        if (errors !== null && errors.length > 0) {
            internalRef.current.reportValidity();
        }
    }, [errors]);

    return (
        <input
            ref={(ref) => {
                internalRef.current = ref;
                if (inputRef !== null && isFunction(inputRef)) {
                    inputRef(ref);
                } else if (inputRef !== null) {
                    inputRef.current = ref;
                }
            }}
            type={type}
            name={name}
            id={id}
            value={value || ''}
            placeholder={placeholder}
            required={required}
            className={classNames([
                styles.container,
                {
                    [styles.withBorder]: withBorder,
                    [className]: className !== null,
                },
            ])}
            onChange={onInputChange}
            onInput={onInput}
            onFocus={onFocus}
            onBlur={onBlur}
            onPointerUp={onPointerUp}
            {...(autoComplete !== null ? { autoComplete } : {})}
            {...(withoutAutocomplete ? { autoComplete: 'off' } : {})}
        />
    );
}

TextField.propTypes = propTypes;
TextField.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <TextField {...props} inputRef={ref} />);
