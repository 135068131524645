import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';

import * as AppPropTypes from '../lib/PropTypes';

export const LayoutContext = React.createContext({
    isReader: false,
    presentation: 'default',
    menuOpened: false,
    menuOverContent: false,
    contentBreakpoint: null,
    contentWidth: null,
    openMenu: () => {},
    closeMenu: () => {},
});

export function useLayoutContext() {
    const context = useContext(LayoutContext);
    return context;
}

export function useMenu() {
    const {
        menuOpened: opened,
        openMenu: open,
        closeMenu: close,
        menuOverContent: overContent,
    } = useLayoutContext();
    return {
        opened,
        overContent,
        open,
        close,
    };
}

export function useGlobalBrand() {
    const { brand = null } = useLayoutContext();
    return brand;
}

export function useIsReader() {
    const { presentation = 'default' } = useLayoutContext();
    return presentation === 'reader';
}

export function useIsCapture() {
    const { presentation = 'default' } = useLayoutContext();
    return presentation === 'capture';
}

export function usePresentation() {
    const { presentation = 'default' } = useLayoutContext();
    return presentation;
}

const propTypes = {
    presentation: PropTypes.oneOf(['default', 'reader']),
    menuOpened: PropTypes.bool,
    openMenu: PropTypes.func,
    closeMenu: PropTypes.func,
    menuOverContent: PropTypes.bool,
    brand: AppPropTypes.brand,
    children: PropTypes.node,
};

const defaultProps = {
    presentation: 'default',
    menuOpened: false,
    openMenu: () => {},
    closeMenu: () => {},
    menuOverContent: false,
    brand: null,
    children: null,
};

export function LayoutContextProvider({
    presentation,
    menuOpened,
    openMenu,
    closeMenu,
    menuOverContent,
    brand,
    children,
}) {
    const value = useMemo(
        () => ({
            presentation: presentation || 'default',
            menuOpened,
            openMenu,
            closeMenu,
            menuOverContent,
            brand,
        }),
        [menuOpened, menuOverContent, openMenu, closeMenu, brand, presentation],
    );

    return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
}

LayoutContextProvider.propTypes = propTypes;
LayoutContextProvider.defaultProps = defaultProps;
