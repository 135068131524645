/* eslint-disable react/jsx-no-useless-fragment */
import camelCase from 'lodash/camelCase';
import React from 'react';
import { useParams } from 'wouter';

import getVideoSources from '../../lib/getVideoSources';

import { useBrands } from '../../contexts/SiteContext';

const propTypes = {};

const defaultProps = {};

function PreloadBumper() {
    const brands = useBrands();
    const { brand: brandSlug = null } = useParams();
    const brand = brands.find(
        ({ slug, isDefault = false }) => slug === brandSlug || (brandSlug === null && isDefault),
    );
    const { videos: brandVideos = null } = brand || {};

    return ['portrait', 'landscape'].map((orientation) => {
        const { sources = [] } = brandVideos[camelCase(`bumper_${orientation}`)] || null;
        return getVideoSources(sources).map(({ mime, url }) => (
            <link
                key={`${orientation}-${mime}`}
                rel="preload"
                href={url}
                type={mime}
                media={`(orientation: ${orientation})`}
                as="video"
                crossOrigin="anonymous"
            />
        ));
    });
}

PreloadBumper.propTypes = propTypes;
PreloadBumper.defaultProps = defaultProps;

export default PreloadBumper;
