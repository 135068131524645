import { useUrlGenerator } from '@folklore/routes';
import React from 'react';
import { useParams } from 'wouter';

import useTopic from '../../hooks/useTopic';

import Metadata from './Metadata';

const propTypes = {};

const defaultProps = {};

function TopicMetadata() {
    const { slug: slugParam } = useParams();
    const { topic = null } = useTopic(slugParam);
    const url = useUrlGenerator();
    if (topic === null) {
        return null;
    }
    const { label, slug, image, brand, metadata } = topic;
    const { title, description } = metadata || {};
    return (
        <Metadata
            brand={brand}
            title={title || label}
            description={description}
            image={image}
            url={url('topic', {
                slug,
            })}
        />
    );
}

TopicMetadata.propTypes = propTypes;
TopicMetadata.defaultProps = defaultProps;

export default TopicMetadata;
