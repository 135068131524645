import { AdsProvider as BaseAdsProvider } from '@folklore/ads';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { useConsentGiven } from './ConsentContext';

export function AdsProvider({ disabled = false, ...props }) {
    // const consentGiven = useConsentGiven();
    // const [finalDisabled, setFinalDisabled] = useState(true);
    // useEffect(() => {
    //     setFinalDisabled(disabled);
    // }, [disabled, consentGiven]);
    return <BaseAdsProvider disabled={disabled} {...props} />;
}

AdsProvider.propTypes = {
    disabled: PropTypes.bool,
};
