import React from 'react';
import { useParams } from 'wouter';

import useAuthor from '../../hooks/useAuthor';
import { useAuthorSchema } from '../../hooks/useMicroformats';
import { useAuthorUrlGenerator } from '../../hooks/useUrlGenerator';

import Metadata from './Metadata';
import NotfoundMetadata from './NotfoundMetadata';

const propTypes = {};

const defaultProps = {};

function AuthorMetadata() {
    const { slug: slugParam } = useParams();
    const { author = null, notFound = false } = useAuthor(slugParam);
    const url = useAuthorUrlGenerator();
    const authorSchema = useAuthorSchema(author);
    if (notFound) {
        return <NotfoundMetadata />;
    }
    if (author === null) {
        return null;
    }
    const { name, shortDescription, slug, image } = author;
    const pageUrl = url({
        slug,
    });
    return (
        <Metadata
            title={name}
            description={shortDescription}
            image={image}
            url={pageUrl}
            microformats={authorSchema !== null ? [authorSchema] : []}
        />
    );
}

AuthorMetadata.propTypes = propTypes;
AuthorMetadata.defaultProps = defaultProps;

export default AuthorMetadata;
