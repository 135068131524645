/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import EditContact from '../forms/EditContactForm';
import ProfileForm from '../forms/ProfileForm';

import styles from '../../styles/panels/account-profile-panel.module.css';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function AccountProfilePanel({ className }) {
    return (
        <div className={classNames([styles.container, className])}>
            <div className={styles.rowWrapper}>
                <div className={styles.row}>
                    <EditContact
                        type="email"
                        className={styles.form}
                        inputClassName={styles.input}
                    />
                    <EditContact
                        type="phone"
                        className={styles.form}
                        inputClassName={styles.input}
                    />
                </div>
            </div>
            <ProfileForm withDelete className={styles.form} inputClassName={styles.input} />
        </div>
    );
}

AccountProfilePanel.propTypes = propTypes;
AccountProfilePanel.defaultProps = defaultProps;

export default AccountProfilePanel;
