import React from 'react';
import { useParams } from 'wouter';

import useDocument, { useDocumentPreview } from '../../hooks/useDocument';
import { useDocumentSchema } from '../../hooks/useMicroformats';
import { useDocumentUrlGenerator } from '../../hooks/useUrlGenerator';

import Metadata from './Metadata';
import NotfoundMetadata from './NotfoundMetadata';

const propTypes = {};

const defaultProps = {};

function DocumentMetadata() {
    const { slug: slugParam, previewToken = null } = useParams();
    const { document = null, notFound = false } =
        previewToken !== null ? useDocumentPreview(previewToken) : useDocument(slugParam);
    const url = useDocumentUrlGenerator();
    const documentSchema = useDocumentSchema(document);
    if (notFound) {
        return <NotfoundMetadata />;
    }
    if (document === null) {
        return null;
    }
    const { type, title, slug, metadata, image, images } = document;
    const { share: shareImage = null } = images || {};
    const { description, brands, keywords } = metadata || {};
    const [brand] = brands || [];
    const { slug: brandSlug } = brand || {};
    return (
        <Metadata
            brand={brand}
            title={title}
            description={description}
            keywords={keywords}
            image={shareImage || image}
            url={
                slug !== null
                    ? url({
                          type,
                          slug,
                      })
                    : null
            }
            rssUrl={`/${brandSlug || 'articles'}.rss`}
            atomUrl={`/${brandSlug || 'articles'}.atom`}
            microformats={documentSchema !== null ? [documentSchema] : []}
        />
    );
}

DocumentMetadata.propTypes = propTypes;
DocumentMetadata.defaultProps = defaultProps;

export default DocumentMetadata;
