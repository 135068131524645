import { useQuery, useQueryClient, keepPreviousData as previousPlaceholder } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useApi } from '../contexts/ApiContext';

export function prefetchLocation(queryClient, api, slug) {
    return queryClient.prefetchQuery({
        queryKey: ['location', slug],
        queryFn: ({ queryKey: [, queryParam], signal }) =>
            api.locations.findBySlug(queryParam, { signal }),
    });
}

export function fetchLocation(queryClient, api, slug) {
    return queryClient.fetchQuery({
        queryKey: ['location', slug],
        queryFn: ({ queryKey: [, queryParam], signal }) =>
            api.locations.findBySlug(queryParam, { signal }),
    });
}

export function usePrefetchLocation() {
    const api = useApi();
    const queryClient = useQueryClient();
    return useCallback((slug) => prefetchLocation(queryClient, api, slug), [queryClient, api]);
}

export default function useLocation(slug, opts = null) {
    const api = useApi();
    const { keepPreviousData = true } = opts || {};
    const { data: location, ...queryResult } = useQuery({
        queryKey: ['location', slug],
        queryFn: ({ queryKey: [, queryParam], signal }) =>
            api.locations.findBySlug(queryParam, { signal }),
        suspense: false,
        placeholderData: keepPreviousData ? previousPlaceholder : undefined,
        enabled: slug !== null,
        ...opts,
    });

    return {
        location,
        ...queryResult,
    };
}
