import { useVideoPlayer } from '@folklore/hooks';
import { useVideoTracking } from '@folklore/tracking';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import * as AppPropTypes from '../../lib/PropTypes';
import getVideoSources from '../../lib/getVideoSources';

import styles from '../../styles/partials/bump.module.css';

const propTypes = {
    brand: AppPropTypes.brand,
    playing: PropTypes.bool,
    playTimeout: PropTypes.number,
    onEnd: PropTypes.func,
    onPlay: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    brand: null,
    playing: false,
    playTimeout: 500,
    onEnd: null,
    onPlay: null,
    className: null,
};

function Bump({ brand, playing: shouldPlay, playTimeout, onEnd, onPlay, className }) {
    const { handle, videos: brandVideos, label } = brand;
    const { bumperPortrait: defaultVideo = null } = brandVideos || {};
    const { name: defaultVideoName = null } = defaultVideo || {};
    const videoName =
        defaultVideoName !== null ? defaultVideoName.replace(/(Horizontal|Vertical)/, '') : null;
    const videoId = videoName !== null ? `${videoName}-${handle}` : null;
    const player = useVideoPlayer({
        videoId,
        url: videoId,
        muted: true,
        initialMuted: true,
        onEnd,
        onPlay,
    });
    const { ref: videoRef, playing = false, duration } = player;
    useVideoTracking(player, {
        id: videoId,
        platform: 'native',
        title: videoName !== null ? `${label} - ${videoName}` : null,
        duration,
    });

    // const onVideoSuspend = useCallback(
    //     (e) => {
    //         const video = e.currentTarget;
    //         const isVideoPlaying = !!(
    //             video.currentTime > 0 &&
    //             !video.paused &&
    //             !video.ended &&
    //             video.readyState > 2
    //         );
    //         console.log('SUSPEND', isVideoPlaying);
    //         if (!isVideoPlaying && onEnd !== null) {
    //             onEnd();
    //         }
    //     },
    //     [onEnd],
    // );

    useEffect(() => {
        const { current: video = null } = videoRef;
        const isVideoPlaying = video !== null && !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
        if (playing || !shouldPlay || isVideoPlaying) {
            return () => {};
        }
        const timeout = setTimeout(() => {
            if (onEnd !== null) {
                onEnd();
            }
        }, playTimeout);
        return () => {
            clearTimeout(timeout);
        };
    }, [handle, playing, shouldPlay, onEnd, playTimeout]);

    return (
        <div
            className={classNames([styles.container, styles[videoName], styles[handle], className])}
        >
            <video
                key={`video-${handle}`}
                ref={videoRef}
                className={styles.video}
                preload="auto"
                muted
                playsInline
                autoPlay={shouldPlay}
                // crossOrigin="anonymous"
                // onSuspend={onVideoSuspend}
            >
                {['portrait', 'landscape', null].map((orientation) => {
                    const { sources = [] } =
                        brandVideos[camelCase(`bumper_${orientation || 'portrait'}`)] || {};
                    return getVideoSources(sources).map(({ mime, url }) => (
                        <source
                            key={`source-${orientation || 'default'}-${mime}`}
                            src={`${url}#t=0.001`}
                            type={mime}
                            media={orientation !== null ? `(orientation: ${orientation})` : null}
                        />
                    ));
                })}
            </video>
        </div>
    );
}

Bump.propTypes = propTypes;
Bump.defaultProps = defaultProps;

export default Bump;
