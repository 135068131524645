/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable import/prefer-default-export */
import { AuthContext, useAuthContext, useUser } from '@folklore/auth';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { useAuthCheck } from '../hooks/useAuth';

export function useIsCheckingAuth() {
    const { isLoading } = useAuthContext();
    return isLoading;
}

export function useUserRole() {
    const { role = null } = useUser() || {};
    return role;
}

export function AuthContextProvider({ disabled, children }) {
    const { user = null, isLoading = false } = useAuthCheck({
        enabled: !disabled,
    });

    const value = useMemo(
        () => ({
            user:
                user !== null
                    ? {
                          role:
                              (user.email || '').match(/@urbania\.(ca|fr)$/) !== null
                                  ? 'staff'
                                  : null,
                          ...user,
                      }
                    : null,
            isLoading,
        }),
        [user, isLoading],
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthContextProvider.propTypes = {
    disabled: PropTypes.bool,
    children: PropTypes.node.isRequired,
};
AuthContextProvider.defaultProps = {
    disabled: false,
};
