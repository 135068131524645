import _ from 'lodash';

import Base from './Base';

class Profile extends Base {
    update(data, opts = null) {
        return this.postJSONWithSession('/profile', data, opts);
    }

    updateContact(data, opts = null) {
        return this.postJSONWithSession('/profile/contacts', data, opts);
    }

    verifyContact({ id: contactId }, data, opts = null) {
        return this.postJSONWithSession(`/profile/contacts/${contactId}/verify`, data, opts);
    }

    cancelContact({ id: contactId }, data, opts = null) {
        return this.postJSONWithSession(`/profile/contacts/${contactId}/cancel`, data, opts);
    }

    delete(data, opts = null) {
        return this.postJSONWithSession(
            '/profile',
            {
                _method: 'DELETE',
                ...data,
            },
            opts,
        );
    }
}

export default Profile;
