/* eslint-disable react/no-danger */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import styles from '../../styles/blocks/table-block.module.css';

const propTypes = {
    rows: PropTypes.arrayOf(PropTypes.shape({})),
    className: PropTypes.string,
};

const defaultProps = {
    rows: null,
    className: null,
};

function TableBlock({ rows, className }) {
    const sections = useMemo(
        () =>
            [
                { tag: 'thead', rows: rows.filter(({ section }) => section === 'header') },
                {
                    tag: 'tbody',
                    rows: rows.filter(
                        ({ section = null }) => section === 'body' || section === null,
                    ),
                },
                { tag: 'tfoot', rows: rows.filter(({ section }) => section === 'footer') },
            ].filter(({ rows: sectionRows }) => sectionRows.length > 0),
        [rows],
    );

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <table width="100%" cellPadding="0" cellSpacing="0" className={styles.table}>
                {(sections || []).map(({ tag: SectionComponent, rows: sectionRows }) => (
                    <SectionComponent>
                        {sectionRows.map(({ cells }) => (
                            <tr>
                                {cells.map(({ heading, align, colspan, body: cellBody }) => {
                                    const CellComponent = heading ? 'th' : 'td';
                                    return (
                                        <CellComponent
                                            align={align}
                                            colSpan={colspan}
                                            dangerouslySetInnerHTML={{ __html: cellBody }}
                                        />
                                    );
                                })}
                            </tr>
                        ))}
                    </SectionComponent>
                ))}
            </table>
        </div>
    );
}

TableBlock.propTypes = propTypes;
TableBlock.defaultProps = defaultProps;

export default TableBlock;
