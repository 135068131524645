import React from 'react';
import { FormattedMessage } from 'react-intl';

export default {
    subscribe: ({ subscription } = {}) => {
        const { labels = null } = subscription || {};
        const { to: label = null } = labels || {};
        return label !== null ? (
            <FormattedMessage
                defaultMessage="Vous êtes maintenant abonné·e {label}!"
                description="Message"
                values={{ label }}
            />
        ) : (
            <FormattedMessage
                defaultMessage="Vous êtes maintenant abonné·e!"
                description="Message"
            />
        );
    },

    already_subscribed: ({ subscription } = {}) => {
        const { labels = null } = subscription || {};
        const { to: label = null } = labels || {};
        return label !== null ? (
            <FormattedMessage
                defaultMessage="Vous êtes déjà abonné·e {label}!"
                description="Message"
                values={{ label }}
            />
        ) : (
            <FormattedMessage defaultMessage="Vous êtes déjà abonné·e!" description="Message" />
        );
    },

    unsubscribe: ({ subscription } = {}) => {
        const { labels = null } = subscription || {};
        const { of: label = null } = labels || {};
        return label !== null ? (
            <FormattedMessage
                defaultMessage="Vous êtes maintenant désabonné·e {label}!"
                description="Message"
                values={{ label }}
            />
        ) : (
            <FormattedMessage
                defaultMessage="Vous êtes maintenant désabonné·e!"
                description="Message"
            />
        );
    },

    hi: ({ isNew = true, user } = {}) => {
        const { firstname = null } = user || {};
        if (isNew) {
            return firstname !== null ? (
                <FormattedMessage
                    defaultMessage="Bonjour {firstname}!"
                    description="Message"
                    values={{ firstname }}
                />
            ) : (
                <FormattedMessage defaultMessage="Bonjour!" description="Message" />
            );
        }
        return firstname !== null ? (
            <FormattedMessage
                defaultMessage="Rebonjour {firstname}!"
                description="Message"
                values={{ firstname }}
            />
        ) : (
            <FormattedMessage defaultMessage="Rebonjour!" description="Message" />
        );
    },

    contact_updated: ({ type }) =>
        type === 'phone' ? (
            <FormattedMessage
                defaultMessage="Votre numéro de téléphone a été mis à jour!"
                description="Message"
            />
        ) : (
            <FormattedMessage
                defaultMessage="Votre adresse courriel a été mise à jour!"
                description="Message"
            />
        ),

    verify_contact: ({ type }) =>
        type === 'phone' ? (
            <FormattedMessage
                defaultMessage="Veuillez confirmer votre numéro de téléphone."
                description="Message"
            />
        ) : (
            <FormattedMessage
                defaultMessage="Veuillez confirmer votre adresse courriel."
                description="Message"
            />
        ),

    profile_deleted: () => (
        <FormattedMessage
            defaultMessage="Votre compte a été supprimé avec succès."
            description="Message"
        />
    ),
};
