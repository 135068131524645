/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import React from 'react';

import { useDocumentSurtitle } from '../../hooks/useSurtitle';
import { useDocumentUrlGenerator } from '../../hooks/useUrlGenerator';
// import { FormattedMessage } from 'react-intl';
// import useFontSize from '../../hooks/useFontSize';
import * as AppPropTypes from '../../lib/PropTypes';
import getRatio from '../../lib/getRatio';

import Author from '../partials/Author';
import Category from '../partials/Category';
import DocumentImage from '../partials/DocumentImage';
import Duration from '../partials/Duration';
import PlaceholderText from '../partials/PlaceholderText';
import Sponsor from '../partials/Sponsor';
import Surtitle from '../partials/Surtitle';
import CardTitle from '../typography/CardTitle';
// import SponsorContent from '../typography/SponsorContent';
import OverCard from './OverCard';

import styles from '../../styles/cards/document-over-card.module.css';

const propTypes = {
    type: PropTypes.string,
    sponsors: AppPropTypes.sponsors,
    brands: AppPropTypes.taxonomies,
    slug: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    linkQuery: PropTypes.object,
    title: PropTypes.string,
    surtitle: PropTypes.string,
    image: AppPropTypes.image,
    coverImage: AppPropTypes.image,
    categories: AppPropTypes.taxonomies,
    credits: AppPropTypes.credits,
    topic: AppPropTypes.taxonomy,
    format: AppPropTypes.taxonomy,
    collection: AppPropTypes.collection,
    season: PropTypes.string,
    episode: PropTypes.string,
    micromagNumber: PropTypes.string,
    theme: PropTypes.oneOf([null, 'without_text', 'outline']),
    size: AppPropTypes.cardSize,
    ratio: AppPropTypes.cardRatio,
    duration: PropTypes.number,
    placeholder: PropTypes.bool,
    width: PropTypes.number,
    imageLoading: AppPropTypes.loading,
    withoutAuthor: PropTypes.bool,
    withoutIcon: PropTypes.bool,
    withoutBrandIcon: PropTypes.bool,
    withoutTypeIcon: PropTypes.bool,
    withCategory: PropTypes.bool,
    withoutSurtitle: PropTypes.bool,
    withoutSerie: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

const defaultProps = {
    type: null,
    brands: null,
    slug: null,
    linkQuery: null,
    title: null,
    surtitle: null,
    image: null,
    duration: null,
    coverImage: null,
    categories: null,
    credits: null,
    sponsors: null,
    topic: null,
    format: null,
    collection: null,
    season: null,
    episode: null,
    micromagNumber: null,
    theme: null,
    size: null,
    width: 120,
    ratio: null,
    placeholder: false,
    imageLoading: undefined,
    withoutAuthor: false,
    withoutIcon: false,
    withoutBrandIcon: false,
    withoutTypeIcon: false,
    withCategory: false,
    withoutSurtitle: false,
    withoutSerie: false,
    className: null,
    onClick: null,
};

function DocumentOverCard({
    type,
    slug,
    linkQuery,
    brands,
    image,
    coverImage,
    categories,
    credits,
    sponsors,
    title,
    surtitle,
    duration,
    topic,
    format,
    collection,
    season,
    episode,
    micromagNumber,
    theme,
    size,
    width,
    ratio,
    placeholder,
    imageLoading,
    withoutAuthor,
    withoutIcon,
    withoutBrandIcon,
    withoutTypeIcon,
    withCategory,
    withoutSurtitle,
    withoutSerie,
    className,
    onClick,
}) {
    const url = useDocumentUrlGenerator();
    const [{ author = null } = {}] = credits || [];
    const [category = null] = categories || [];
    const [brand = null] = brands || [];
    const { handle: brandHandle } = brand || {};
    const [sponsor = null] = sponsors || [];
    // const fontSize = useFontSize(title, 240, 80);

    const finalSurtitle = useDocumentSurtitle(
        {
            type,
            surtitle,
            collection,
            topic,
            format,
            season,
            episode,
            categories,
            micromagNumber,
        },
        {
            withoutSerie,
            serieFirst: true,
            withCategory: !withCategory,
            withoutMicromagDate: true,
        },
    );

    const isOutline = theme === 'outline';
    const thumbnailRatio = getRatio(ratio, 4 / 5);
    const showDuration = type === 'video' || type === 'podcast';

    return (
        <OverCard
            theme={theme}
            href={
                url({
                    type,
                    slug,
                    linkQuery,
                }) || '#'
            }
            onClick={!placeholder ? onClick : null}
            thumbnail={
                <>
                    <DocumentImage
                        type={type}
                        brand={brandHandle}
                        width={width}
                        height={width / thumbnailRatio}
                        media={type === 'micromag' ? coverImage || image : image}
                        className={styles.thumbnail}
                        iconClassName={styles.thumbnailIcon}
                        typeIconClassName={styles.typeIcon}
                        loading={imageLoading}
                        size="medium"
                        withoutSize
                        withPlaceholder
                        withIcon={!withoutIcon}
                        withoutBrandIcon={withoutBrandIcon}
                        withoutTypeIcon={withoutTypeIcon}
                    />
                    {duration !== null && duration > 0 && showDuration ? (
                        <Duration
                            duration={duration}
                            timestamp={type === 'video'}
                            className={styles.duration}
                        />
                    ) : null}
                </>
            }
            thumbnailRatio={thumbnailRatio}
            className={classNames([
                styles.container,
                {
                    [styles.withSponsor]: sponsor !== null,
                },
                styles[type],
                styles[camelCase(size)],
                styles[camelCase(theme)],
                className,
            ])}
            innerClassName={styles.inner}
            labelClassName={styles.label}
        >
            {(category !== null || placeholder) && withCategory ? (
                <Category {...category} placeholder={placeholder} className={styles.category} />
            ) : null}
            {finalSurtitle !== null && !withoutSurtitle ? (
                <Surtitle surtitle={finalSurtitle} className={styles.surtitle} />
            ) : null}
            {isOutline ? (
                <CardTitle className={styles.title}>
                    {placeholder ? <PlaceholderText lines={2} /> : title}
                </CardTitle>
            ) : (
                <CardTitle className={styles.title}>
                    {placeholder ? <PlaceholderText lines={2} /> : title}
                </CardTitle>
            )}

            {sponsor !== null ? <Sponsor {...sponsor} textOnly className={styles.sponsor} /> : null}
            {sponsor === null && author !== null && !withoutAuthor ? (
                <Author {...author} withBox withoutImage className={styles.author} />
            ) : null}
        </OverCard>
    );
}

DocumentOverCard.propTypes = propTypes;
DocumentOverCard.defaultProps = defaultProps;

export default React.memo(DocumentOverCard);
