import { useUser } from '@folklore/auth';
import Cookies from 'js-cookie';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSearch } from 'wouter';

export function getConsentFromCookie() {
    let cookieConsentGiven = Cookies.get('consent_given');
    if (typeof cookieConsentGiven !== 'undefined' && !isEmpty(cookieConsentGiven)) {
        cookieConsentGiven = cookieConsentGiven === 'true';
    } else {
        cookieConsentGiven = null;
    }
    return cookieConsentGiven;
}

const cookieConsentGiven = getConsentFromCookie();

const defaultConsent = {
    ad_storage: cookieConsentGiven === true ? 'granted' : 'denied',
    ad_user_data: cookieConsentGiven === true ? 'granted' : 'denied',
    ad_personalization: cookieConsentGiven === true ? 'granted' : 'denied',
    personalization_storage: cookieConsentGiven === true ? 'granted' : 'denied',
    analytics_storage: 'granted',
    functionality_storage: 'granted',
    security_storage: 'granted',
};

export const ConsentContext = React.createContext({
    consentGiven: cookieConsentGiven === true,
    consent: defaultConsent,
    update: () => {},
});

export function useConsentContext() {
    const context = useContext(ConsentContext);
    return context;
}

export function useUpdateConsent() {
    const { update } = useConsentContext();
    return update;
}

export function useConsentGiven() {
    const { consentGiven } = useConsentContext();
    return consentGiven;
}

export function useConsent(features) {
    const { consent } = useConsentContext();
    const hasConsent = useMemo(
        () =>
            (isArray(features) ? features : [features]).reduce(
                (all, feature) => all && consent[feature] === 'granted',
                true,
            ),
        [features, consent],
    );
    return hasConsent;
}

export function useConsentNeeded() {
    const consentGiven = useConsentGiven();
    const search = useSearch();
    const user = useUser();
    const { askConsent = false } = useMemo(() => queryString.parse(search), [search]);
    return (user === null && consentGiven === null) || askConsent === true || askConsent === 'true';
}

const propTypes = {
    children: PropTypes.node,
    expiration: PropTypes.number,
};

const defaultProps = {
    children: null,
    expiration: 7,
};

export function ConsentContextProvider({ children, expiration }) {
    const user = useUser();
    const [consentGiven, setConsentGiven] = useState(user !== null ? true : getConsentFromCookie());
    const [consent, setConsent] = useState({
        ad_storage: consentGiven || user !== null ? 'granted' : 'denied',
        ad_user_data: consentGiven || user !== null ? 'granted' : 'denied',
        ad_personalization: consentGiven || user !== null ? 'granted' : 'denied',
        personalization_storage: consentGiven || user !== null ? 'granted' : 'denied',
        analytics_storage: 'granted',
        functionality_storage: 'granted',
        security_storage: 'granted',
    });

    const update = useCallback(
        (newConsentGiven) => {
            const newConsent = {
                ad_storage: newConsentGiven ? 'granted' : 'denied',
                ad_user_data: newConsentGiven ? 'granted' : 'denied',
                ad_personalization: newConsentGiven ? 'granted' : 'denied',
                personalization_storage: newConsentGiven ? 'granted' : 'denied',
                analytics_storage: 'granted',
                functionality_storage: 'granted',
                security_storage: 'granted',
            };

            Cookies.set('consent_given', newConsentGiven ? 'true' : 'false', {
                expires: expiration,
            });
            if (typeof window.updateConsent !== 'undefined') {
                window.updateConsent(newConsent);
            }
            if (typeof window.dataLayer !== 'undefined') {
                window.dataLayer.push({
                    event: 'consent_update',
                    consentGiven: newConsentGiven,
                });
                if (newConsentGiven) {
                    window.dataLayer.push({
                        event: 'consent_given',
                    });
                }
            }

            setConsent({
                ...consent,
                ...newConsent,
            });
            setConsentGiven(newConsentGiven);
        },
        [consent, setConsent, setConsentGiven, expiration],
    );

    useEffect(() => {
        if (user !== null && consentGiven === null) {
            update(true);
        }
    }, [user, consentGiven, update]);

    const value = useMemo(
        () => ({
            consentGiven,
            update,
            consent,
        }),
        [consent, consentGiven, update],
    );

    return <ConsentContext.Provider value={value}>{children}</ConsentContext.Provider>;
}

ConsentContextProvider.propTypes = propTypes;
ConsentContextProvider.defaultProps = defaultProps;
