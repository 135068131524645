/* eslint-disable react/jsx-props-no-spreading */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'wouter';

import * as AppPropTypes from '../../lib/PropTypes';

import { useBrands } from '../../contexts/SiteContext';
import PillsMenu from '../menus/PillsMenu';

import styles from '../../styles/partials/index-filters.module.css';

const propTypes = {
    route: PropTypes.string.isRequired,
    popularRoute: PropTypes.string,
    sort: PropTypes.oneOf([null, 'popular']),
    brand: AppPropTypes.brand,
    withoutBrands: PropTypes.bool,
    withoutSort: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    popularRoute: null,
    sort: null,
    brand: null,
    withoutBrands: false,
    withoutSort: false,
    className: null,
};

function IndexFilters({ route, popularRoute, sort, brand, withoutBrands, withoutSort, className }) {
    const url = useUrlGenerator();
    const [location] = useLocation();

    const brands = useBrands();
    const { handle: brandHandle = null, slug: brandSlug = null } = brand || {};

    const brandFilters = useMemo(() => {
        if (brands.length === 0) {
            return null;
        }
        return (brands || []).map((currentBrand) => {
            const { label = null, handle = null, slug } = currentBrand || {};

            let brandUrl = url(route, {
                brand: slug,
            });
            if (sort === 'popular') {
                brandUrl =
                    popularRoute !== null
                        ? url(popularRoute, {
                              brand: slug,
                          })
                        : `${url(route, {
                              brand: slug,
                          })}?sort=popular`;
            }
            return {
                label,
                handle,
                href: brandUrl,
                bordered: true,
                branded: true,
                active: brandHandle === handle,
            };
        }, []);
    }, [brands, brandHandle, sort]);

    const types = [
        {
            id: 'all',
            label: <FormattedMessage defaultMessage="Tout" description="Filter label" />,
        },
        {
            id: 'articles',
            label: <FormattedMessage defaultMessage="Articles" description="Filter label" />,
        },
        {
            id: 'videos',
            label: <FormattedMessage defaultMessage="Vidéos" description="Filter label" />,
        },
        {
            id: 'podcasts',
            label: <FormattedMessage defaultMessage="Podcasts" description="Filter label" />,
        },
        {
            id: 'collections',
            label: <FormattedMessage defaultMessage="Collections" description="Filter label" />,
        },
    ].filter((it) => it !== null);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.inner}>
                <div className={styles.group}>
                    {brandFilters.length > 1 && !withoutBrands && false ? (
                        <PillsMenu
                            // title={
                            //     <FormattedMessage
                            //         defaultMessage="Filtrer par média"
                            //         description="Filter title"
                            //     />
                            // }
                            items={brandFilters}
                            itemsClassName={styles.items}
                            itemClassName={styles.item}
                            buttonClassName={styles.button}
                        />
                    ) : null}
                    <PillsMenu
                        // title={
                        //     <FormattedMessage defaultMessage="Trier par" description="Filter title" />
                        // }
                        items={types.map(({ id, label }) => ({
                            id,
                            href: url(id, {
                                brand: brandSlug,
                            }),
                            label,
                            bordered: true,
                            branded: true,
                            active:
                                location ===
                                    url(id, {
                                        brand: brandSlug,
                                    }) ||
                                (id === 'all' && location === url('popular', { brand: brandSlug })),
                        }))}
                        itemsClassName={styles.items}
                        itemClassName={styles.item}
                        buttonClassName={styles.button}
                    />
                </div>
                {!withoutSort ? (
                    <PillsMenu
                        // title={
                        //     <FormattedMessage defaultMessage="Trier par" description="Filter title" />
                        // }
                        items={[
                            {
                                id: 'recent',
                                href: url(route, {
                                    brand: brandSlug,
                                }),
                                label: (
                                    <FormattedMessage
                                        defaultMessage="Plus récents"
                                        description="Filter label"
                                    />
                                ),
                                bordered: true,
                                branded: true,
                                active: sort === null,
                            },
                            {
                                id: 'popular',
                                href:
                                    popularRoute !== null
                                        ? url(popularRoute, {
                                              brand: brandSlug,
                                          })
                                        : `${url(route, {
                                              brand: brandSlug,
                                          })}?sort=popular`,
                                label: (
                                    <FormattedMessage
                                        defaultMessage="Plus populaires"
                                        description="Filter label"
                                    />
                                ),
                                bordered: true,
                                branded: true,
                                active: sort === 'popular',
                            },
                        ]}
                        itemsClassName={styles.items}
                        itemClassName={styles.item}
                    />
                ) : null}
            </div>
        </div>
    );
}

IndexFilters.propTypes = propTypes;
IndexFilters.defaultProps = defaultProps;

export default IndexFilters;
