import React from 'react';
import { defineMessage } from 'react-intl';

export const urbania = [
    {
        id: 'search',
    },
    {
        id: 'brands',
        list: 'brands',
        // title: (
        // defineMessage({ defaultMessage:"Nos médias", description:"Menu section heading" })
        // ),
        cardRatio: 12 / 5,
        columns: 1,
        cardType: 'horizontal',
        cardTheme: 'menu-reverse',
        presentation: 'grid',
    },
    {
        id: 'authors',
        route: 'authors',
        list: 'authors',
        loading: 'lazy',
        title: defineMessage({
            defaultMessage: 'Tous les auteurs',
            description: 'Menu section heading',
        }),
        count: 12,
        query: {
            for: 'documents',
            order: 'count',
            person: true,
        },
        presentation: 'grid',
        columns: 4,
        cardType: 'vertical',
        cardSize: 'small',
        cardImageLoading: 'lazy',
    },
    {
        id: 'micromags',
        route: 'micromags',
        title: defineMessage({ defaultMessage: 'Micromags', description: 'Menu section heading' }),
        icon: 'micromag',
        list: 'documents',
        loading: 'lazy',
        type: 'micromag',
        query: {
            collection: 'micromag-urbania',
        },
        cardRatio: 'micromag',
        cardType: 'over',
        cardSize: 'small',
        cardTheme: 'without_text',
        cardWithout: ['brand_icon', 'type_icon'],
        presentation: 'grid',
        columns: 3,
        count: 9,
        cardImageLoading: 'lazy',
    },
    {
        id: 'videos',
        route: 'videos',
        title: defineMessage({
            defaultMessage: 'Séries vidéos',
            description: 'Menu section heading',
        }),
        query: {
            order: 'new',
            type: 'serie',
            handle: [
                'entre-moi-et-moi',
                'passes-croises',
                'comment-faire-lamour',
                'courrier-recommande',
                'tel-que-vu-sur-le-web',
                'portrait',
            ],
        },
        presentation: 'grid',
        icon: 'video',
        list: 'collections',
        loading: 'lazy',
        cardType: 'over',
        cardRatio: 'vertical',
        cardWithout: ['text'],
        columns: 3,
        // cardTheme: 'outline',
        // columns: 1,
        count: 6,
        cardImageLoading: 'lazy',
    },
    {
        id: 'podcasts',
        route: 'podcasts',
        title: defineMessage({ defaultMessage: 'Balados', description: 'Menu section heading' }),
        query: {
            order: 'new',
            type: 'serie_podcast',
        },
        presentation: 'grid',
        icon: 'podcast',
        list: 'collections',
        loading: 'lazy',
        cardType: 'over',
        cardRatio: 'square',
        cardWithout: ['text'],
        columns: 3,
        // cardTheme: 'outline',
        // columns: 1,
        count: 6,
        cardImageLoading: 'lazy',
    },
    {
        id: 'collections',
        route: 'collections',
        title: defineMessage({
            defaultMessage: 'Collections',
            description: 'Menu section heading',
        }),
        query: {
            order: 'popular',
            type: 'collection',
        },
        presentation: 'grid',
        icon: 'collection',
        list: 'collections',
        loading: 'lazy',
        cardType: 'over',
        cardSize: 'small',
        cardRatio: 'horizontal',
        cardTheme: null,
        cardWithout: ['text'],
        // cardTheme: 'menu',
        // columns: 1,
        count: 6,
        // columns: 1,
        cardImageLoading: 'lazy',
    },
    {
        id: 'categories',
        route: 'categories',
        title: defineMessage({
            defaultMessage: 'Catégories',
            description: 'Menu section heading',
        }),
        icon: 'category',
        list: 'categories',
        presentation: 'inline',
        count: 12,
        query: {
            for: 'documents',
            order: 'count',
        },
        cardType: 'horizontal',
        cardTheme: 'pill',
        cardSize: 'small',
        cardImageLoading: 'lazy',
    },
    {
        id: 'topics',
        route: 'topics',
        title: defineMessage({
            defaultMessage: 'Sujets du moment',
            description: 'Menu section heading',
        }),
        icon: 'category',
        list: 'topics',
        presentation: 'inline',
        count: 12,
        query: {
            for: 'documents',
            order: 'count',
        },
        cardType: 'horizontal',
        cardTheme: 'pill',
        cardSize: 'small',
        cardImageLoading: 'lazy',
    },
];

export const france = [
    {
        id: 'search',
    },
    {
        id: 'authors',
        route: 'authors',
        list: 'authors',
        loading: 'lazy',
        title: defineMessage({
            defaultMessage: 'Tous les auteurs',
            description: 'Menu section heading',
        }),
        count: 12,
        query: {
            for: 'documents',
            order: 'count',
            person: true,
        },
        presentation: 'grid',
        columns: 4,
        cardType: 'vertical',
        cardSize: 'small',
        cardImageLoading: 'lazy',
    },
    {
        id: 'micromags',
        route: 'micromags',
        title: defineMessage({ defaultMessage: 'Micromags', description: 'Menu section heading' }),
        icon: 'micromag',
        list: 'documents',
        loading: 'lazy',
        type: 'micromag',
        query: {
            collection: 'micromag-france',
        },
        cardRatio: 'micromag',
        cardType: 'over',
        cardSize: 'small',
        cardTheme: 'without_text',
        cardWithout: ['brand_icon', 'type_icon'],
        presentation: 'grid',
        columns: 3,
        count: 9,
        cardImageLoading: 'lazy',
    },
    {
        id: 'videos',
        route: 'videos',
        title: defineMessage({
            defaultMessage: 'Séries vidéos',
            description: 'Menu section heading',
        }),
        query: {
            order: 'new',
            type: 'serie',
            handle: [
                '5-trucs',
                'mic',
                'darwins-awards',
                'francis-recap',
                'en-59-secondes',
                'prof-apeupres',
            ],
        },
        presentation: 'grid',
        icon: 'video',
        list: 'collections',
        loading: 'lazy',
        cardType: 'over',
        cardRatio: 'vertical',
        cardWithout: ['text'],
        columns: 3,
        count: 6,
        cardImageLoading: 'lazy',
    },
    {
        id: 'collections',
        route: 'collections',
        title: defineMessage({
            defaultMessage: 'Collections',
            description: 'Menu section heading',
        }),
        query: {
            order: 'new',
            type: 'collection',
        },
        presentation: 'grid',
        icon: 'collection',
        list: 'collections',
        loading: 'lazy',
        cardType: 'over',
        cardSize: 'small',
        cardRatio: 'horizontal',
        cardTheme: null,
        cardWithout: ['text'],
        count: 6,
        cardImageLoading: 'lazy',
    },
    {
        id: 'categories',
        route: 'categories',
        title: defineMessage({
            defaultMessage: 'Catégories',
            description: 'Menu section heading',
        }),
        icon: 'category',
        list: 'categories',
        presentation: 'inline',
        count: 12,
        query: {
            for: 'documents',
            order: 'count',
        },
        cardType: 'horizontal',
        cardTheme: 'pill',
        cardSize: 'small',
        cardImageLoading: 'lazy',
    },
    // {
    //     id: 'topics',
    //     route: 'topics',
    //     title: (
    //         defineMessage({
    //             defaultMessage:"Sujets du moment"
    //             description:"Menu section heading"
    //         })
    //     ),
    //     icon: 'category',
    //     list: 'topics',
    //     presentation: 'inline',
    //     count: 12,
    //     query: {
    //         for: 'documents',
    //         order: 'count',
    //     },
    //     cardType: 'horizontal',
    //     cardTheme: 'pill',
    //     cardSize: 'small',
    //     cardImageLoading: 'lazy',
    // },
];
