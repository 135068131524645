import { useTracking } from '@folklore/tracking';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';

import { usePopupsContext } from '../../contexts/PopupsContext';
import IdentifyForm from '../forms/IdentifyForm';
import ModalDescription from '../typography/ModalDescription';
import ModalTitle from '../typography/ModalTitle';
import Dialog from './Dialog';
import Modal from './Modal';

import styles from '../../styles/popups/identify-modal.module.css';

const propTypes = {
    promotion: AppPropTypes.promotion,
    brand: PropTypes.string,
    className: PropTypes.string,
    onClosed: PropTypes.func,
};

const defaultProps = {
    promotion: null,
    brand: null,
    className: null,
    onClosed: null,
};

function IdentifyModal({ promotion, brand, className, onClosed }) {
    const { title = null, description = null } = promotion || {};
    const [opened, setOpened] = useState(true);
    const tracking = useTracking();
    const [fieldHasValue, setFieldHasValue] = useState(null);
    const { addMessage } = usePopupsContext();
    const formRef = useRef(null);

    const onIdentify = useCallback(
        ({ user = null, isNew = false }) => {
            setOpened(false);
            addMessage('hi', {
                isNew,
                user,
            });
        },
        [addMessage],
    );

    const onFormChange = useCallback(
        (newFormValue) => {
            const { field = null } = newFormValue || {};
            setFieldHasValue(field !== null && field.length > 0);
        },
        [setFieldHasValue],
    );

    const onRequestClose = useCallback(() => {
        setOpened(false);
    }, [setOpened]);

    return (
        <Modal
            id="identify"
            opened={opened}
            withBackdrop
            withTransition
            // withScrollLock
            onRequestClose={onRequestClose}
            className={classNames([
                styles.container,
                { [className]: className !== null, [styles[brand]]: brand !== null },
            ])}
            onClosed={onClosed}
        >
            <Dialog className={styles.dialog}>
                <div className={styles.content}>
                    <ModalTitle className={styles.title} html={title}>
                        {title === null ? (
                            <FormattedMessage
                                defaultMessage="Pour continuer..."
                                description="Identify modal title"
                            />
                        ) : null}
                    </ModalTitle>
                    <ModalDescription className={styles.description} html={description}>
                        {description === null ? (
                            <FormattedMessage
                                defaultMessage="<p>Vous devez vous identifier</p>"
                                description="Identify modal description"
                            />
                        ) : null}
                    </ModalDescription>
                </div>
                <IdentifyForm
                    ref={formRef}
                    source="identify-modal"
                    className={styles.form}
                    onComplete={onIdentify}
                    onChange={onFormChange}
                />
            </Dialog>
        </Modal>
    );
}

IdentifyModal.propTypes = propTypes;
IdentifyModal.defaultProps = defaultProps;

export default IdentifyModal;
