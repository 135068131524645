/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'wouter';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/buttons/basic-button.module.css';

const propTypes = {
    type: PropTypes.oneOf(['button']),
    href: PropTypes.string,
    external: PropTypes.bool,
    target: PropTypes.string,
    download: PropTypes.string,
    disabled: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    onClick: PropTypes.func,
    buttonRef: AppPropTypes.ref,
};

const defaultProps = {
    type: 'button',
    href: null,
    external: false,
    target: null,
    download: null,
    disabled: false,
    children: null,
    className: null,
    onClick: null,
    buttonRef: null,
};

function BasicButton({
    type,
    href,
    external,
    target,
    download,
    children,
    disabled,
    className,
    onClick,
    buttonRef,
}) {
    if (href !== null && !external) {
        return (
            <Link
                href={href}
                onClick={onClick}
                ref={buttonRef}
                className={classNames([
                    styles.container,
                    {
                        [className]: className !== null,
                    },
                ])}
            >
                {children}
            </Link>
        );
    }

    const ButtonComponent = href !== null ? 'a' : 'button';

    return (
        <ButtonComponent
            type={ButtonComponent === 'button' ? type : null}
            href={href}
            target={target === null && external ? '_blank' : target}
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            disabled={disabled}
            onClick={onClick}
            download={download}
            ref={buttonRef}
        >
            {children}
        </ButtonComponent>
    );
}

BasicButton.propTypes = propTypes;
BasicButton.defaultProps = defaultProps;

// eslint-disable-next-line react/jsx-props-no-spreading
export default React.forwardRef((props, ref) => <BasicButton {...props} buttonRef={ref} />);
