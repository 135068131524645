import { useUser } from '@folklore/auth';
import { useTracking } from '@folklore/tracking';
import { useQuery, keepPreviousData as previousPlaceholder } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import * as AppPropTypes from '../lib/PropTypes';

import { useApi } from './ApiContext';
import { useSite } from './SiteContext';

import micromagFree from '../assets/img/promotions/micromag_free.svg';

// import q95Sondage from '../assets/img/promotions/quatre95_sondage_2024.png';

export const PromotionsContext = React.createContext({});

export function prefetchPromotions(queryClient, api, site) {
    const { handle: siteHandle } = site;
    return queryClient.prefetchQuery({
        queryKey: ['promotions', siteHandle],
        queryFn: ({ queryKey: [, siteParam], signal }) =>
            api.promotions.get(
                {
                    site: siteParam,
                },
                null,
                null,
                { signal },
            ),
        staleTime: 1000 * 60 * 30,
    });
}

export function usePromotionsQuery(opts = {}) {
    const api = useApi();
    const { keepPreviousData = true } = opts || {};
    const { handle: siteHandle } = useSite();
    const { data = null, ...queryResult } = useQuery({
        queryKey: ['promotions', siteHandle],
        queryFn: ({ queryKey: [, siteParam], signal }) =>
            api.promotions.get(
                {
                    site: siteParam,
                },
                null,
                null,
                { signal },
            ),
        suspense: false,
        staleTime: 1000 * 60 * 30,
        placeholderData: keepPreviousData ? previousPlaceholder : null,
        ...opts,
    });

    const { data: items = null, ...metadata } = isArray(data) ? { data } : data || {};

    return data !== null
        ? {
              items,
              ...metadata,
              ...queryResult,
          }
        : queryResult;
}

export function usePromotions() {
    const context = useContext(PromotionsContext);
    return context;
}

export function useMatchedPromotions() {
    const { matchedPromotions } = usePromotions();
    return matchedPromotions || [];
}

const propTypes = {
    fixedPromotions: PropTypes.arrayOf(AppPropTypes.promotion),
    children: PropTypes.node,
};

const defaultProps = {
    fixedPromotions: [
        {
            id: 'consent',
            type: 'consent',
            placement: 'modal',
            force: true,
            priority: 100,
            site: 'urbania',
            title: 'Avant de continuer…',
            description:
                '<p><strong>URBANIA est un média indépendant et gratuit grâce à vous.</strong></p>',
            loginDescription:
                'En rejoignant la communauté URBANIA, vous recevrez du contenu exclusif et personnalisé, des liens de prévente à nos événements et des rabais sur notre boutique.',
            cookiesDescription:
                '<p>Vous ne souhaitez pas vous abonner? Alright! Acceptez simplement les cookies pour accéder au contenu de notre site.</p>',
            trigger: {
                event: 'one',
                triggers: ['identify', 'pageview'],
                pageMatch: {
                    notType: ['micromags', 'micromag', 'login', 'account', 'auth'],
                },
                delay: 2000,
                hasPage: true,
                identified: true,
                authenticated: false,
                consentGiven: null,
            },
        },

        {
            id: 'consent',
            type: 'consent',
            placement: 'modal',
            force: true,
            priority: 100,
            site: 'france',
            title: 'Avant de continuer…',
            description:
                '<p><strong>URBANIA est un média indépendant et gratuit grâce à vous.</strong></p>',
            cookiesDescription:
                '<p>Vous pouvez accepter les cookies pour accéder au contenu de notre site.</p>',
            loginDescription:
                'Rejoignez la communauté URBANIA et recevez le meilleur de nos contenus, sélectionnés pour vous, toutes les deux semaines dans votre boîte mail.',
            trigger: {
                event: 'one',
                triggers: ['identify', 'pageview'],
                pageMatch: {
                    notType: ['micromags', 'micromag', 'login', 'account', 'auth'],
                },
                delay: 2000,
                hasPage: true,
                identified: true,
                authenticated: false,
                consentGiven: null,
            },
        },

        {
            id: 'subscribe_micromags',
            handle: 'subscribe_micromags',
            type: 'subscription',
            placement: 'notification',
            subscription: 'micromag',
            closeable: false,
            force: true,
            site: 'urbania',
            title: 'Abonnez-vous à URBANIA',
            description: null,
            image: {
                url: micromagFree,
            },
            theme: 'micromag',
            trigger: {
                event: 'pageview',
                once: false,
                pageMatch: {
                    type: ['micromags'],
                },
            },
            closeTrigger: {
                event: 'pageview',
                once: false,
                pageMatch: {
                    notType: ['micromags'],
                },
            },
        },

        {
            id: 'subscribe_micromags',
            handle: 'subscribe_micromags',
            type: 'subscription',
            placement: 'notification',
            subscription: 'micromag_france',
            closeable: false,
            force: true,
            site: 'france',
            title: 'Abonnez-vous à URBANIA',
            description: null,
            image: {
                url: micromagFree,
            },
            theme: 'micromag',
            trigger: {
                event: 'pageview',
                once: false,
                pageMatch: {
                    type: ['micromags'],
                },
            },
            closeTrigger: {
                event: 'pageview',
                once: false,
                pageMatch: {
                    notType: ['micromags'],
                },
            },
        },

        {
            id: 'subscription_block_france',
            handle: 'subscription_block_france',
            type: 'subscription',
            placement: 'block',
            subscription: 'france',
            site: 'france',
            title: 'Abonnez-vous à la newsletter de URBANIA',
            description:
                'Et recevez, un jeudi sur 2, votre dose de contenus drôles et intelligents directement en intraveineuse dans votre boîte mail.',
            image: {
                url: 'https://cdn.urbania.ca/media/2024/12/icon-france-prof-1.png',
            },
            // theme: 'mollo',
            matchTrigger: {
                event: 'pageview',
                once: false,
            },
        },

        {
            id: 'subscription_block_urbania',
            handle: 'subscription_block_urbania',
            type: 'subscription',
            placement: 'block',
            subscription: 'urbania',
            site: 'urbania',
            title: 'Abonnez-vous à l’infolettre de URBANIA',
            description:
                'Et recevez, chaque semaine, nos contenus qui soulèvent les passions. Un pas de recul, sérieux ou non, sur l’actualité de la semaine.',
            image: {
                url: 'https://cdn.urbania.ca/media/2024/12/icon-urbania-hugo.png',
            },
            // theme: 'mollo',
            matchTrigger: {
                event: 'pageview',
                once: false,
            },
        },

        {
            id: 'subscription_block_mollo_parent_fun',
            handle: 'subscription_block_mollo_parent_fun',
            type: 'subscription',
            placement: 'block',
            subscription: 'mollo',
            site: 'urbania',
            title: 'Abonnez-vous à l’infolettre Mollo',
            description:
                'Et recevez nos meilleurs contenus de type « parent-le-fun » chaque jeudi.',
            image: {
                url: 'https://cdn.urbania.ca/media/2024/08/Maman.png',
            },
            priority: 10,
            // theme: 'mollo',
            matchTrigger: {
                event: 'pageview',
                once: false,
                pageMatch: {
                    brands: ['mollo'],
                },
            },
        },
        {
            id: 'subscription_block_quatre95',
            handle: 'subscription_block_quatre95',
            type: 'subscription',
            placement: 'block',
            subscription: 'quatre95',
            site: 'urbania',
            title: 'Abonnez-vous à l’infolettre de Quatre95',
            description:
                'Et recevez nos meilleurs contenus chaque jeudi. Vous en aurez pour votre argent.',
            image: {
                url: 'https://cdn.urbania.ca/media/2024/12/icon-quatre95-hand.png',
            },
            priority: 10,
            // theme: 'mollo',
            matchTrigger: {
                event: 'pageview',
                once: false,
                pageMatch: {
                    brands: ['quatre95'],
                },
            },
        },

        {
            id: 'subscription_block_hugo_meunier',
            handle: 'subscription_block_hugo_meunier',
            type: 'subscription',
            placement: 'block',
            subscription: 'notification_hugo-meunier',
            site: 'urbania',
            title: 'Vous êtes fan d’Hugo Meunier ?',
            description:
                'Recevez un texto à chaque fois qu’il <s>fait du karaoké</s> sort un contenu',
            image: {
                url: 'https://images.urbania.ca/image/2024-08-06/90460-033504-filters(large).jpg',
            },
            priority: 100,
            // theme: 'mollo',
            matchTrigger: {
                event: 'pageview',
                once: false,
                pageMatch: {
                    authors: ['hugomeunier'],
                },
            },
        },
        {
            id: 'subscription_block_jean_bourbeau',
            handle: 'subscription_block_jean_bourbeau',
            type: 'subscription',
            placement: 'block',
            subscription: 'notification_jean-bourbeau',
            site: 'urbania',
            title: 'Adepte des récits de Jean Bourbeau ?',
            description: 'Recevez une alerte par texto pour chacun de ses textes.',
            image: {
                url: 'https://images.urbania.ca/image/2024-08-06/90463-033648-filters(large).jpg',
            },
            priority: 100,
            // theme: 'mollo',
            matchTrigger: {
                event: 'pageview',
                once: false,
                pageMatch: {
                    authors: ['jeanbourbeau'],
                },
            },
        },

        {
            id: 'subscription_block_benoit_lelievre',
            handle: 'subscription_block_benoit_lelievre',
            type: 'subscription',
            placement: 'block',
            subscription: 'notification_benoit-lelievre',
            site: 'urbania',
            title: 'Vous aimez tout ce que Ben écrit?',
            description: 'Recevez une alerte par texto pour chacun de ses textes.',
            image: {
                url: 'https://images.urbania.ca/image/2024-11-25/93937-015455-filters(large).jpg',
            },
            priority: 100,
            // theme: 'mollo',
            matchTrigger: {
                event: 'pageview',
                once: false,
                pageMatch: {
                    authors: ['benoitlelievre'],
                },
            },
        },

        // {
        //     id: 'subscribe_button',
        //     type: 'button',
        //     placement: 'notification',
        //     closeable: false,
        //     force: true,
        //     callToAction: {
        //         label: 'S’abonner',
        //         url: '/abonnement',
        //     },
        //     trigger: {
        //         event: 'pageview',
        //         pageMatch: {
        //             type: ['micromags']
        //         },
        //     },
        // },

        // {
        //     id: 'quatre95_sondage_2024',
        //     site: 'urbania',
        //     placement: 'notification',
        //     theme: 'quatre95',
        //     priority: 0,
        //     trigger: {
        //         event: 'pageview',
        //         pageMatch: {
        //             brands: ['quatre95'],
        //         },
        //         delay: 2000,
        //     },
        //     title: 'Courez la chance de gagner 100$',
        //     description:
        //         'en répondant à un sondage sur le contenu Quatre95. <strong>Ça ne vous prendra que quelques minutes, promis.</strong>',
        //     image: {
        //         url: q95Sondage,
        //     },
        //     url: 'https://cube.ca1.qualtrics.com/jfe/form/SV_d1kjCoUlLD7oNPU?Source=SW',
        //     callToAction: {
        //         type: 'button',
        //         label: 'Je participe',
        //         external: true,
        //     },
        // },

        // {
        //     id: 'mollogotchi',
        //     // type: 'subscription',
        //     placement: 'notification',
        //     force: true,
        //     // theme: 'full',
        //     // subscription: 'micromag',
        //     image: {
        //         url: '',
        //     },
        //     title: 'Mollogotchi',
        //     description: 'Essayez Mollogotchi',
        //     trigger: {
        //         event: 'one',
        //         triggers: [
        //             {

        //             }
        //         ]
        //     },
        // },
    ],
    children: null,
};

export function PromotionsProvider({ fixedPromotions, children }) {
    const { handle: siteHandle } = useSite();
    const { items: loadedPromotions = null } = usePromotionsQuery();
    const [runtimePromotions, setRuntimePromotions] = useState([]);
    const { subscriptions: userSubscriptions = null } = useUser() || {};
    const promotions = useMemo(
        () =>
            loadedPromotions !== null
                ? [
                      ...(fixedPromotions || []),
                      ...(loadedPromotions || []),
                      ...(runtimePromotions || []),
                  ].filter(({ site = null }) => site === null || site === siteHandle)
                : [],
        [fixedPromotions, loadedPromotions, runtimePromotions, siteHandle],
    );

    const [donePromotions, setDonePromotions] = useState(
        (Cookies.get('promotions_done') || '').split(',').filter((it) => it !== ''),
    );

    const userSubscribed = useMemo(
        () =>
            (userSubscriptions || [])
                .filter(({ subscribed = false }) => subscribed)
                .map(({ id }) => id),
        [userSubscriptions],
    );

    const markAsDone = useCallback(
        (id) => {
            const newDonePromotions =
                donePromotions.indexOf(id) === -1 ? [...donePromotions, id] : donePromotions;
            setDonePromotions(newDonePromotions);
            Cookies.set('promotions_done', newDonePromotions.join(','), {
                expires: 365,
            });
        },
        [setDonePromotions, donePromotions],
    );

    const filteredPromotions = useMemo(
        () => [
            ...(promotions || [])
                .filter(
                    ({ id, subscription = null, force = false }) =>
                        (force || donePromotions.indexOf(id) === -1) &&
                        (subscription === null || userSubscribed.indexOf(subscription) === -1),
                )
                .sort(({ priority: aPriority = 0 }, { priority: bPriority = 0 }) => {
                    if (aPriority === bPriority) {
                        return 0;
                    }
                    return aPriority > bPriority ? -1 : 1;
                }),
        ],
        [fixedPromotions, promotions, donePromotions, userSubscribed],
    );

    const [matchedPromotions, setMatchedPromotions] = useState([]);
    const tracking = useTracking();
    useEffect(() => {
        const matchPromotions = promotions.filter(
            ({ matchTrigger = null }) => matchTrigger !== null,
        );
        matchPromotions.forEach((promotion) => {
            const { id, matchTrigger } = promotion;
            tracking.triggers.register(`promotion_match_${id}`, matchTrigger, () => {
                setMatchedPromotions((currentPromotions) =>
                    [
                        ...currentPromotions.filter(({ id: currentId }) => id !== currentId),
                        {
                            ...promotion,
                            matchTime: new Date().getTime(),
                        },
                    ].sort(
                        (
                            { priority: aPriority = 0, matchTime: aMatchTime = 0 },
                            { priority: bPriority = 0, matchTime: bMatchTime = 0 },
                        ) => {
                            if (aPriority === bPriority) {
                                return aMatchTime > bMatchTime ? -1 : 1;
                            }
                            return aPriority > bPriority ? -1 : 1;
                        },
                    ),
                );
            });
        });
        return () => {
            matchPromotions.forEach((promotion) => {
                const { id } = promotion;
                tracking.triggers.unregister(`promotion_match_${id}`);
            });
        };
    }, [filteredPromotions, tracking]);

    const addPromotion = useCallback(
        (promotion) => {
            // console.log('add', promotion);
            setRuntimePromotions((currentPromotions) => [
                ...currentPromotions.filter(({ id }) => promotion.id !== id),
                promotion,
            ]);
        },
        [setRuntimePromotions],
    );

    const removePromotion = useCallback(
        (removeId) => {
            // console.log('remove', removeId);
            setRuntimePromotions((currentPromotions) =>
                currentPromotions.filter(({ id }) => removeId !== id),
            );
        },
        [setRuntimePromotions],
    );

    const value = useMemo(
        () => ({
            promotions: filteredPromotions,
            allPromotions: promotions,
            matchedPromotions,
            markAsDone,
            addPromotion,
            removePromotion,
        }),
        [promotions, filteredPromotions, matchedPromotions, markAsDone, addPromotion],
    );

    return <PromotionsContext.Provider value={value}>{children}</PromotionsContext.Provider>;
}

PromotionsProvider.propTypes = propTypes;
PromotionsProvider.defaultProps = defaultProps;
