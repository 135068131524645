/* eslint-disable react/no-array-index-key */

/* eslint-disable react/jsx-props-no-spreading */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { usePopularDocuments } from '../../hooks/useDocuments';
import useUniqueDocumentsBlocks from '../../hooks/useUniqueDocumentsBlocks';
import * as AppPropTypes from '../../lib/PropTypes';
import { createLayoutRow, createVideosRow } from '../../lib/blocks';

import { useSite } from '../../contexts/SiteContext';
import UrbaniaHeader from '../headers/UrbaniaHeader';
import RowTitle from '../partials/RowTitle';
import HomePageBlocks from './HomePageBlocks';

import styles from '../../styles/pages/home-page-france.module.css';

const propTypes = {
    brand: AppPropTypes.brand.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};
function HomePageFrance({ brand, className, ...props }) {
    const url = useUrlGenerator();
    const { handle: siteHandle } = useSite();

    const sections = [
        {
            id: 'actualites',
            label: 'Actualité',
            query: {
                section: 'actualites',
            },
            url: url('section', { section: 'actualites' }),
        },
        {
            id: 'culture',
            label: 'Culture',
            query: {
                section: 'culture',
            },
            url: url('section', { section: 'culture' }),
        },
        {
            id: 'societe',
            label: 'Société',
            query: {
                section: 'societe',
            },
            url: url('section', { section: 'societe' }),
        },
        {
            id: 'feminisme',
            label: 'Féminisme',
            query: {
                topic: 'feminisme',
            },
            url: url('topic', { slug: 'feminisme' }),
        },
        {
            id: 'humour',
            label: 'Humour',
            query: {
                section: 'humour',
            },
            url: url('section', { section: 'humour' }),
        },
        {
            id: 'environnement',
            label: 'Environnement',
            query: {
                category: 'environnement',
            },
            url: url('category', { slug: 'environnement' }),
        },
        {
            id: 'politique',
            label: 'Politique',
            query: {
                category: 'politique',
            },
            url: url('category', { slug: 'politique' }),
        },
        {
            id: 'humains',
            label: 'Humains',
            query: {
                section: 'humains',
            },
            url: url('section', { section: 'humains' }),
        },
        {
            id: 'chronique',
            label: 'Chronique',
            query: {
                format: 'chronique',
            },
            url: url('format', { slug: 'chronique' }),
        },
    ];

    const blocks = useUniqueDocumentsBlocks([
        {
            role: 'block',
            type: 'header_featured_list',
            brand,
            cardTheme: 'over-bottom-left',
            cardWithout: ['category'],
            count: 1,
            className: styles.featuredBlock,
            innerClassName: styles.featuredInner,
            cardClassName: styles.featuredCard,
            query: {
                brand: brand.id,
                exclude_type: ['contest', 'newsletter'],
                placement: ['home_featured_france'],
            },
            uniqueDocuments: true,
        },
        createLayoutRow('1_1_1_4', {
            cardTheme: 'box-translucent',
            title: (
                <RowTitle
                    url={url('all', {
                        brand: brand.slug,
                    })}
                >
                    <FormattedMessage
                        defaultMessage="Les plus récents"
                        description="Section title"
                    />
                </RowTitle>
            ),
            query: {
                brand: brand.id,
                exclude_type: ['newsletter'],
                placement: ['home_new_france', 'home_featured_france'],
                // type: ['article', 'video'],
            },
            clickRef: 'home_new',
            cardWithout: ['brand-icon'],
            loading: 'lazy',
            cardImageLoading: 'lazy',
            uniqueDocuments: true,
        }),
        createLayoutRow('1_1_1_4', {
            cardTheme: 'box-translucent',
            title: (
                <RowTitle
                    url={url('popular', {
                        brand: brand.slug,
                    })}
                >
                    <FormattedMessage
                        defaultMessage="Les plus populaires"
                        description="Section title"
                    />
                </RowTitle>
            ),
            query: {
                brand: brand.id,
                placement: ['home_popular_france', 'home_featured_france'],
                // popular: 'week',
            },
            clickRef: 'home_popular',
            hook: usePopularDocuments,
            loading: 'lazy',
            cardImageLoading: 'lazy',
            cardWithout: ['brand-icon'],
            uniqueDocuments: true,
        }),
        {
            role: 'block',
            type: 'header_featured_sidebar',
            presentation: 'row',
            itemsType: 'documents',
            title: <RowTitle url={url('micromags')}>Micromag URBANIA</RowTitle>,
            query: {
                type: 'micromag',
                collection: `micromag-${siteHandle}`,
            },
            loading: 'lazy',
            cardImageLoading: 'lazy',
            featuredCardType: 'featured-micromag',
            featuredCardTheme: 'left',
            featuredCardRatio: 'micromag',
            featuredCardWith: ['image-link'],
            featuredCardWithout: ['link'],
            cardType: 'over',
            cardSize: 'big',
            cardTheme: 'outline',
            cardRatio: 'micromag',
            responsive: true,
            cardWidth: 200,
            // count: 9,
            cardWithout: ['text', 'brand-icon', 'type-icon'],
            withBorderRadius: false,
            featuredCardClassName: styles.featuredMicromag,
        },
        createVideosRow({
            title: (
                <RowTitle url={url('videos')}>
                    <FormattedMessage
                        defaultMessage="Vidéos récentes"
                        description="Section title"
                    />
                </RowTitle>
            ),
            query: {
                brand: brand.id,
            },
            clickRef: 'home_videos',
            loading: 'lazy',
            cardImageLoading: 'lazy',
            cardWithout: ['category'],
            uniqueDocuments: true,
        }),
        {
            type: 'collections-row',
            presentation: 'grid',
            title: (
                <RowTitle url={url('videos')}>
                    <FormattedMessage defaultMessage="Séries vidéos" description="Section title" />
                </RowTitle>
            ),
            count: 6,
            query: {
                // brand: brand.id,
                handle: [
                    '5-trucs',
                    'mic',
                    'darwins-awards',
                    'francis-recap',
                    'en-59-secondes',
                    'prof-apeupres',
                ],
            },
            loading: 'lazy',
            cardImageLoading: 'lazy',
            cardType: 'vertical',
            cardRatio: 'vertical',
            cardTheme: 'box-translucent',
            cardWidth: 200,
            cardWithout: ['brand-icon'],
        },

        ...sections.map(({ id: sectionId, query, label, url: sectionUrl }) =>
            createLayoutRow('1_1_1_4', {
                role: 'block',
                title: <RowTitle url={sectionUrl}>{label}</RowTitle>,
                loading: 'lazy',
                query,
                clickRef: `home_section_${sectionId}`,
                cardType: 'vertical',
                cardTheme: 'box-translucent',
                cardWithout: ['brand-icon', 'category'],
                uniqueDocuments: true,
            }),
        ),

        {
            type: 'authors-row',
            presentation: 'grid',
            minColumns: 1,
            maxColumns: 4,
            className: styles.collectionsRow,
            title: <RowTitle>Dans l’univers URBANIA</RowTitle>,
            count: 8,
            query: {
                // brand: brand.id,
                handle: [
                    'ouissem',
                    'matthieu-beigbeder',
                    'mathieu-gilbert',
                    'malia-kounkou',
                    'oriane-olivier',
                    'louise-pierga',
                    'hanneli-victoire',
                    'benoit-lelievre',
                ],
                for: 'documents',
                order: 'count',
            },
            loading: 'lazy',
            cardImageLoading: 'lazy',
            cardType: 'documents',
            cardRatio: 'square',
            cardSize: 'medium',
            cardTheme: 'box-translucent',
            cardWidth: 300,
            cardProps: {
                listProps: {
                    loading: 'lazy',
                    cardImageLoading: 'lazy',
                    cardWithout: ['brand-icon'],
                },
                listCount: 4,
            },
            cardWithout: ['brand-icon', 'text'],
        },
    ]);
    return (
        <HomePageBlocks
            {...props}
            brand={brand}
            blocks={blocks}
            header={<UrbaniaHeader className={styles.header} />}
            className={classNames([styles.container, className])}
            blocksClassName={styles.blocks}
        />
    );
}

HomePageFrance.propTypes = propTypes;
HomePageFrance.defaultProps = defaultProps;

export default HomePageFrance;
