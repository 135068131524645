import { useMemo } from 'react';

import { formatDurationIso8601 } from '../lib/utils';

import { useAuthorUrlGenerator, useCanonicalUrl, useDocumentUrlGenerator } from './useUrlGenerator';

export function useAuthorSchema(author) {
    const url = useAuthorUrlGenerator();
    const canonicalUrl = useCanonicalUrl();
    return useMemo(() => {
        if (author === null) {
            return null;
        }
        const {
            name,
            shortDescription = null,
            slug = null,
            image = null,
            title = null,
        } = author || {};
        const { url: imageUrl = null } = image || {};
        const pageUrl = url({
            slug,
        });
        return {
            '@context': 'http://schema.org/',
            '@type': 'Person',
            identifier: canonicalUrl(pageUrl),
            name,
            jobTitle: title,
            image: imageUrl,
            description: shortDescription,
            url: canonicalUrl(pageUrl),
        };
    }, [author, url, canonicalUrl]);
}

export function useVideoSchema(video) {
    return useMemo(() => {
        if (video === null) {
            return null;
        }
        const {
            url = null,
            width = null,
            height = null,
            duration = null,
            embed = null,
        } = video || {};
        const { iframeUrl = null } = embed || {};
        return {
            '@context': 'http://schema.org/',
            '@type': 'Video',
            identifier: url,
            width,
            height,
            embed: iframeUrl,
            duration: formatDurationIso8601(duration),
        };
    }, [video]);
}

export function useDocumentSchema(document) {
    const url = useDocumentUrlGenerator();
    const canonicalUrl = useCanonicalUrl();
    const { metadata: { credits = null } = {}, video = null } = document || {};
    const [{ author = null } = {}] = credits || [];
    const authorSchema = useAuthorSchema(author);
    const videoSchema = useVideoSchema(video);
    return useMemo(() => {
        if (document === null) {
            return null;
        }
        const {
            title = null,
            metadata: {
                createdAt = null,
                publishedAt = null,
                modifiedAt = null,
                description = null,
            } = {},
            image = null,
        } = document || {};
        const pageUrl = url(document);
        const { url: thumbnailUrl = null } = image || {};
        return {
            '@context': 'http://schema.org/',
            '@type': 'Article',
            identifier: canonicalUrl(pageUrl),
            url: canonicalUrl(pageUrl),
            name: title,
            description,
            thumbnailUrl,
            video: videoSchema,
            author: authorSchema,
            dateCreated: createdAt,
            dateModified: modifiedAt,
            datePublished: publishedAt,
        };
    }, [document, authorSchema, videoSchema, url, canonicalUrl]);
}
