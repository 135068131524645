import queryString from 'query-string';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSearch } from 'wouter';

import Metadata from './Metadata';

const propTypes = {};

const defaultProps = {};

function SearchMetadata() {
    const intl = useIntl();
    const search = useSearch();
    const { q = null } = useMemo(() => queryString.parse(search), [search]);
    return (
        <Metadata
            title={intl.formatMessage({
                defaultMessage: 'Recherche',
                description: 'Page title',
            })}
            noIndex={q !== null}
        />
    );
}

SearchMetadata.propTypes = propTypes;
SearchMetadata.defaultProps = defaultProps;

export default SearchMetadata;
