import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'wouter';

import useBrandFromLocation from '../../hooks/useBrandFromLocation';

import { LayoutContextProvider } from '../../contexts/LayoutContext';
import PageRoutes from '../PageRoutes';

const propTypes = {
    presentation: PropTypes.string,
};

const defaultProps = {
    presentation: 'reader',
};

function ReaderLayout({ presentation }) {
    const [location] = useLocation();
    const { brand: finalBrand } = useBrandFromLocation(location);
    return (
        <LayoutContextProvider brand={finalBrand} presentation={presentation}>
            <div data-theme="reader">
                <PageRoutes location={location} current adsDisabled={presentation === 'capture'} />
            </div>
        </LayoutContextProvider>
    );
}

ReaderLayout.propTypes = propTypes;
ReaderLayout.defaultProps = defaultProps;

export default ReaderLayout;
