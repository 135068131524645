/* eslint-disable react/jsx-props-no-spreading */
import { useUrlGenerator } from '@folklore/routes';
import { useTracking } from '@folklore/tracking';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearch } from 'wouter';

import useCollections from '../../hooks/useCollections';
import useFiltersQuery from '../../hooks/useFiltersQuery';
import useScrollEnter from '../../hooks/useScrollEnter';
import { usePageViewTracking } from '../../hooks/useTracking';

import { useBrand, useDefaultBrand } from '../../contexts/SiteContext';
import CollectionsRowBlock from '../blocks/CollectionsRowBlock';
import IndexDefaultHeader from '../headers/IndexDefaultHeader';
import InfiniteItemsBlocks from '../lists/InfiniteItemsBlocks';
import IndexFilters from '../partials/IndexFilters';
import RowTitle from '../partials/RowTitle';

import styles from '../../styles/pages/podcasts-page.module.css';

const propTypes = {
    brandSlug: PropTypes.string,
    current: PropTypes.bool,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    onEnter: PropTypes.func,
    onLeave: PropTypes.func,
};

const defaultProps = {
    brandSlug: null,
    current: false,
    className: null,
    contentClassName: null,
    onEnter: null,
    onLeave: null,
};

function PodcastsPage({ brandSlug, current, className, contentClassName, onEnter, onLeave }) {
    const filters = useFiltersQuery();
    const hasBrand = brandSlug !== null;
    const { handle: defaultBrandHandle = 'urbania' } = useDefaultBrand();
    const brand = useBrand(brandSlug, !hasBrand ? defaultBrandHandle : null);
    const { handle: brandHandle = null } = brand || {};

    const search = useSearch();
    const { sort: sortQuery = null } = useMemo(() => queryString.parse(search), [search]);

    useScrollEnter({
        disabled: !current,
        onEnter,
        onLeave,
    });

    usePageViewTracking(current, {
        brand,
        pageType: 'index',
    });

    const { items: collections = null } = useCollections({
        brand: brand.id,
        type: 'serie_podcast',
    });

    const hasCollections = collections !== null && collections.length > 0;

    return (
        <div
            className={classNames([styles.container, className, contentClassName])}
            data-brand={brandHandle}
        >
            <IndexDefaultHeader
                title={<FormattedMessage defaultMessage="Balados" description="Page title" />}
                className={styles.header}
            />
            <main className={styles.content}>
                <IndexFilters
                    route="podcasts"
                    sort={sortQuery}
                    brand={brand}
                    className={styles.filters}
                />
                {hasCollections ? (
                    <>
                        <CollectionsRowBlock
                            title={
                                <RowTitle className={styles.title}>
                                    <FormattedMessage
                                        defaultMessage="Séries"
                                        description="Row title"
                                    />
                                </RowTitle>
                            }
                            presentation="grid"
                            items={collections}
                            withoutLoader
                            cardType="vertical"
                            cardRatio="vertical"
                            cardSize="big"
                            cardWithout={['brand-icon', 'text']}
                            cardTheme="box-translucent"
                            className={styles.series}
                        />
                        <RowTitle className={styles.sectionTitle}>
                            <FormattedMessage defaultMessage="Épisodes" description="Row title" />
                        </RowTitle>
                    </>
                ) : null}
                <InfiniteItemsBlocks
                    query={{
                        type: 'podcast',
                        brand: brand.id,
                        ...filters,
                    }}
                    cardType="vertical"
                    cardRatio="square"
                    // cardSize="medium"
                    cardWithout={['brand-icon', 'author', 'category']}
                    itemsBlocks={[
                        {
                            type: 'items',
                            itemsType: 'documents',
                            presentation: 'grid',
                            cardWidth: 220,
                            cardTheme: 'box-translucent',
                            cardRatio: 'horizontal',
                            cardSize: 'responsive',
                            minColumns: 2,
                            count: 12,
                        },
                    ]}
                />
            </main>
        </div>
    );
}

PodcastsPage.propTypes = propTypes;
PodcastsPage.defaultProps = defaultProps;

export default PodcastsPage;
