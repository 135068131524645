import { slots } from '@folklore/ads';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

function addSizeToSlot(slot, newSize) {
    const { sizeMapping = null } = slot;
    const newSizesMapping = isObject(newSize) ? newSize : null;
    let newSizes;
    if (isObject(newSize)) {
        // newSizes = Object.keys(newSize).reduce((all, key) => [...all, ...newSize[key]], []);
        newSizes = [...(newSize.default || [])];
    } else if (isArray(newSize)) {
        newSizes = newSize;
    } else {
        newSizes = [newSize];
    }
    return {
        ...slot,
        ...(sizeMapping !== null
            ? {
                  sizeMapping: Object.keys(sizeMapping).reduce(
                      (acc2, key2) => ({
                          ...acc2,
                          [key2]: [
                              ...sizeMapping[key2],
                              ...(newSizesMapping !== null
                                  ? newSizesMapping[key2] || []
                                  : newSizes),
                          ],
                      }),
                      {},
                  ),
              }
            : null),
    };
}

export default {
    ...slots,
    fullwidth_native: addSizeToSlot(addSizeToSlot(slots.fullwidth, 'fluid'), {
        default: [[420, 640]],
        tablet: [
            [420, 640],
            [740, 750],
        ],
        desktop: [
            [740, 750],
            [1024, 750],
        ],
        laptop: [
            [740, 750],
            [1024, 750],
        ],
    }),
    article_native: addSizeToSlot(addSizeToSlot(slots.content, 'fluid'), {
        default: [[420, 640]],
        tablet: [
            [420, 640],
            [740, 750],
        ],
        desktop: [
            [740, 750],
            [1024, 750],
        ],
        laptop: [
            [740, 750],
            [1024, 750],
        ],
    }),
    article: slots.content,
    bumper: {
        size: [
            [1080, 1920],
            [1920, 1080],
        ],
        sizeMapping: {
            default: [[1080, 1920]],
            tablet: [
                [1080, 1920],
                [1920, 1080],
            ],
            desktop: [
                [1080, 1920],
                [1920, 1080],
            ],
            laptop: [
                [1080, 1920],
                [1920, 1080],
            ],
        },
    },
};
