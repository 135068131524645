/* eslint-disable react/jsx-props-no-spreading */
import { getComponentFromName } from '@folklore/utils';
import PropTypes from 'prop-types';
import React from 'react';

import * as Components from '../cards/blocks';

const propTypes = {
    card: PropTypes.shape({ type: PropTypes.string }),
};

const defaultProps = {
    card: null,
};

function CardBlock({ card, ...otherProps }) {
    const { type: cardType = null } = card || {};
    const Component = getComponentFromName(Components, cardType, null);
    return Component !== null ? <Component card={card} {...(otherProps || {})} /> : null;
}

CardBlock.propTypes = propTypes;
CardBlock.defaultProps = defaultProps;

export default CardBlock;
