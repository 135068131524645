import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { useDocumentSurtitle } from '../../hooks/useSurtitle';
import { useDocumentUrlGenerator } from '../../hooks/useUrlGenerator';
// import { FormattedMessage } from 'react-intl';
// import useFontSize from '../../hooks/useFontSize';
import * as AppPropTypes from '../../lib/PropTypes';
import getRatio from '../../lib/getRatio';

import BasicButton from '../buttons/BasicButton';
import LocationsCountIcon from '../icons/LocationCountIcon';
import Author from '../partials/Author';
import Category from '../partials/Category';
import DocumentImage from '../partials/DocumentImage';
import Duration from '../partials/Duration';
import Sponsor from '../partials/Sponsor';
import Surtitle from '../partials/Surtitle';
import Time from '../partials/Time';
import CardTitle from '../typography/CardTitle';
import HorizontalCard from './HorizontalCard';

import styles from '../../styles/cards/document-horizontal-card.module.css';

const propTypes = {
    type: PropTypes.string,
    slug: PropTypes.string,
    linkQuery: PropTypes.string,
    title: PropTypes.string,
    sponsors: AppPropTypes.sponsors,
    brands: AppPropTypes.brands,
    locations: AppPropTypes.locations,
    surtitle: PropTypes.string,
    image: AppPropTypes.image,
    coverImage: AppPropTypes.image,
    categories: AppPropTypes.taxonomies,
    credits: AppPropTypes.credits,
    duration: PropTypes.number,
    date: PropTypes.string,
    topic: AppPropTypes.taxonomy,
    format: AppPropTypes.taxonomy,
    collection: AppPropTypes.collection,
    season: PropTypes.string,
    episode: PropTypes.string,
    micromagNumber: PropTypes.string,
    theme: PropTypes.oneOf([
        null,
        'menu',
        'box',
        'box-brand',
        'box-inverted',
        'box-dark',
        'box-map',
    ]),
    size: PropTypes.oneOf([null, 'tiny', 'small', 'big']),
    ratio: PropTypes.oneOf(['square', 'vertical', 'horizontal', 'micromag']),
    height: PropTypes.number,
    placeholder: PropTypes.bool,
    responsive: PropTypes.bool,
    imageLoading: AppPropTypes.loading,
    withBorder: PropTypes.bool,
    withLocations: PropTypes.bool,
    withoutAuthor: PropTypes.bool,
    withAuthorImage: PropTypes.bool,
    withCategory: PropTypes.bool,
    withoutSurtitle: PropTypes.bool,
    withoutDuration: PropTypes.bool,
    withoutTime: PropTypes.bool,
    withoutBrandIcon: PropTypes.bool,
    withoutTypeIcon: PropTypes.bool,
    withoutSerie: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

const defaultProps = {
    type: null,
    slug: null,
    linkQuery: null,
    title: null,
    surtitle: null,
    brands: null,
    locations: null,
    image: null,
    coverImage: null,
    categories: null,
    credits: null,
    sponsors: null,
    duration: null,
    date: null,
    topic: null,
    format: null,
    collection: null,
    season: null,
    episode: null,
    micromagNumber: null,
    theme: null,
    size: null,
    ratio: null,
    height: null,
    placeholder: false,
    responsive: false,
    imageLoading: undefined,
    withBorder: false,
    withLocations: false,
    withoutAuthor: false,
    withAuthorImage: false,
    withCategory: false,
    withoutSurtitle: false,
    withoutDuration: false,
    withoutTime: false,
    withoutBrandIcon: false,
    withoutTypeIcon: false,
    withoutSerie: false,
    className: null,
    onClick: null,
};

function DocumentHorizontalCard({
    type,
    brands,
    locations,
    slug,
    linkQuery,
    title,
    surtitle,
    image,
    coverImage,
    categories,
    credits,
    sponsors,
    duration,
    date,
    topic,
    format,
    collection,
    season,
    episode,
    micromagNumber,
    theme,
    size,
    ratio,
    height: providedHeight,
    placeholder,
    responsive,
    imageLoading,
    withBorder,
    withLocations,
    withCategory,
    withoutAuthor,
    withAuthorImage,
    withoutSurtitle,
    withoutDuration,
    withoutTime,
    withoutBrandIcon,
    withoutTypeIcon,
    withoutSerie,
    className,
    onClick,
}) {
    const url = useUrlGenerator();
    const documentUrl = useDocumentUrlGenerator();
    const [category = null] = categories || [];
    const [{ author = null } = {}] = credits || [];
    const [brand = null] = brands || [];
    const { handle: brandHandle } = brand || {};
    const [sponsor = null] = sponsors || [];
    // const fontSize = useFontSize(title, 240, 80);

    const finalSurtitle = useDocumentSurtitle(
        {
            type,
            surtitle,
            collection,
            topic,
            format,
            season,
            episode,
            categories,
            micromagNumber,
            date,
        },
        {
            withoutSerie,
            withCategory: !withCategory,
            withoutMicromagDate: true,
        },
    );

    const isMenu = theme === 'menu';
    const isMicromag = type === 'micromag';
    const isBig = size === 'big';
    const isSmall = size === 'small';
    const isTiny = size === 'tiny';
    const isBox = theme !== null && theme.indexOf('box') === 0;

    let defaultRatio = 1;
    if (theme === 'menu' || type === 'article') {
        defaultRatio = 5 / 4;
    } else if (type === 'video') {
        defaultRatio = 4 / 5;
    } else if (type === 'micromag') {
        defaultRatio = 4 / 6;
    }

    const thumbnailRatio = getRatio(ratio, defaultRatio);

    let defaultHeight = 100;
    if (isBig) {
        defaultHeight = 150;
    } else if (isSmall) {
        defaultHeight = 70;
    } else if (isTiny) {
        defaultHeight = 50;
    }

    const height = providedHeight || defaultHeight;

    const hasDuration = duration !== null && duration > 0;
    const showDuration = type === 'video' || type === 'podcast';
    const showCategory = withCategory && !isTiny;
    const showSurtitle = !withoutSurtitle && !isTiny;
    const Wrapper = withLocations ? 'div' : Fragment;

    return (
        <Wrapper
            {...(withLocations
                ? {
                      className: classNames([styles.wrapper, className]),
                  }
                : null)}
        >
            <HorizontalCard
                href={
                    documentUrl(
                        {
                            type,
                            slug,
                        },
                        linkQuery,
                    ) || '#'
                }
                onClick={!placeholder ? onClick : null}
                thumbnail={
                    <DocumentImage
                        type={type}
                        withIcon={!placeholder && !isTiny}
                        withoutBrandIcon={withoutBrandIcon}
                        withoutTypeIcon={withoutTypeIcon}
                        theme={theme}
                        brand={brandHandle}
                        width={thumbnailRatio * height}
                        height="100%"
                        size="small"
                        // responsive={responsive}
                        withoutSize={responsive}
                        withPlaceholder={placeholder}
                        media={
                            type === 'micromag' && (ratio === 'vertical' || ratio === 'micromag')
                                ? coverImage || image
                                : image
                        }
                        className={classNames([
                            styles.thumbnail,
                            {
                                [styles.gradiant]: isMenu && isMicromag,
                            },
                        ])}
                        loading={imageLoading}
                        iconClassName={styles.thumbnailIcon}
                        pictureClassName={classNames({
                            [styles.containImage]:
                                isMenu && (type === 'micromag' || type === 'podcast'),
                        })}
                    >
                        {hasDuration && showDuration && !withoutDuration && !isTiny ? (
                            <Duration
                                duration={duration}
                                timestamp={type === 'video'}
                                className={styles.duration}
                            />
                        ) : null}
                    </DocumentImage>
                }
                thumbnailRatio={thumbnailRatio}
                height={providedHeight || height}
                responsive={responsive}
                className={classNames([
                    styles.container,
                    {
                        [styles.box]: isBox,
                        [styles.hasSponsor]: sponsor !== null,
                        [styles.hasSurtitle]: finalSurtitle !== null && showSurtitle,
                        [styles.responsive]: responsive,
                        [styles.withBorder]: withBorder && !isBox,
                    },
                    styles[camelCase(theme)],
                    styles[camelCase(size)],
                    className,
                ])}
                innerClassName={styles.inner}
                labelClassName={styles.label}
            >
                {(category !== null || placeholder) && showCategory ? (
                    <Category {...category} placeholder={placeholder} className={styles.category} />
                ) : null}

                {finalSurtitle !== null && showSurtitle ? (
                    <Surtitle surtitle={finalSurtitle} className={styles.surtitle} />
                ) : null}

                <CardTitle className={styles.title} placeholder={placeholder} placeholderLines={3}>
                    {title}
                </CardTitle>

                <span className={styles.meta}>
                    {(placeholder ||
                        (author !== null && type !== 'micromag' && sponsor === null)) &&
                    !withoutAuthor ? (
                        <Author
                            {...author}
                            placeholder={placeholder}
                            className={styles.author}
                            withoutImage={!withAuthorImage}
                        />
                    ) : null}
                    {sponsor !== null ? <Sponsor {...sponsor} className={styles.sponsor} /> : null}

                    {date !== null && !withoutTime ? (
                        <Time date={date} className={styles.time} />
                    ) : null}
                </span>
            </HorizontalCard>
            {withLocations && locations !== null && locations.length > 0 ? (
                <BasicButton
                    href={
                        slug !== null
                            ? url('map.document', {
                                  document: slug,
                              })
                            : null
                    }
                    className={classNames([
                        styles.container,
                        styles.locations,
                        {
                            [styles.box]: isBox,
                            [styles.hasSponsor]: sponsor !== null,
                            [styles.responsive]: responsive,
                            [styles.withBorder]: withBorder,
                        },
                        styles[camelCase(theme)],
                        styles[camelCase(size)],
                        className,
                    ])}
                >
                    <LocationsCountIcon
                        iconCircleColor="transparent"
                        count={locations.length}
                        countClassName={styles.locationCount}
                        className={styles.locationIcon}
                    />
                </BasicButton>
            ) : null}
        </Wrapper>
    );
}

DocumentHorizontalCard.propTypes = propTypes;
DocumentHorizontalCard.defaultProps = defaultProps;

export default React.memo(DocumentHorizontalCard);
