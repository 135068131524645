import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import DocumentClassIcon from '../icons/DocumentClassIcon';
import ExternalLinkIcon from '../icons/ExternalLinkIcon';

import styles from '../../styles/cards/block-class-action-card.module.css';

const propTypes = {
    card: PropTypes.shape({ title: PropTypes.string }),
    className: PropTypes.string,
};

const defaultProps = {
    card: null,
    className: null,
};

function BlockClassActionCard({ card, className }) {
    const {
        title = null,
        description = null,
        category = null,
        url = null,
        amount = null,
        company = null,
    } = card || {};
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.price}>
                {amount !== null ? (
                    <div className={styles.amount}>
                        <span className={styles.label}>
                            <FormattedMessage
                                defaultMessage="Jusqu'à"
                                description="Button label"
                                values={{ amount }}
                            />
                        </span>
                        <span className={classNames([styles.label, styles.bold])}>
                            <FormattedMessage
                                defaultMessage="{amount}$"
                                description="Button label"
                                values={{ amount }}
                            />
                        </span>
                        <div className={styles.arrow} />
                    </div>
                ) : null}
                <DocumentClassIcon className={styles.document} />
            </div>
            <div className={styles.content}>
                {category !== null ? <p className={styles.category}>{category}</p> : null}
                <h3 className={styles.title}>
                    {company !== null ? `${company} - ${title}` : title}
                </h3>
                {description !== null ? <p className={styles.description}>{description}</p> : null}
            </div>
            {url !== null ? (
                <a className={styles.link} href={url} target="_blank" rel="noreferrer noopener">
                    <ExternalLinkIcon className={styles.icon} />
                </a>
            ) : null}
        </div>
    );
}

BlockClassActionCard.propTypes = propTypes;
BlockClassActionCard.defaultProps = defaultProps;

export default BlockClassActionCard;
