import { useUrlGenerator } from '@folklore/routes';
import React from 'react';
import { useParams } from 'wouter';

import useCategory from '../../hooks/useCategory';

import Metadata from './Metadata';

const propTypes = {};

const defaultProps = {};

function CategoryMetadata() {
    const { slug: slugParam } = useParams();
    const { category = null } = useCategory(slugParam);
    const url = useUrlGenerator();
    if (category === null) {
        return null;
    }
    const { label, slug, image, brand, metadata = {} } = category;
    const { title, description } = metadata || {};
    return (
        <Metadata
            brand={brand}
            title={title || label}
            description={description}
            image={image}
            url={url('category', {
                slug,
            })}
        />
    );
}

CategoryMetadata.propTypes = propTypes;
CategoryMetadata.defaultProps = defaultProps;

export default CategoryMetadata;
