/* eslint-disable react/jsx-props-no-spreading */
import { getComponentFromName } from '@folklore/utils';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';
import getImageSize from '../../lib/getImageSize';

import * as ThemeComponents from './index';

const propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    containerClassName: PropTypes.string.isRequired,
    containerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.any }),
    ]),
    name: PropTypes.string,
    primaryColor: PropTypes.string,
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    backgroundImage: AppPropTypes.image,
};

const defaultProps = {
    name: null,
    containerRef: null,
    primaryColor: null,
    textColor: null,
    backgroundColor: null,
    backgroundImage: null,
};

function ArticleTheme({
    id,
    containerClassName,
    containerRef,
    name,
    primaryColor,
    backgroundColor,
    textColor,
    backgroundImage,
    children,
    ...props
}) {
    const { url: backgroundImageUrl = null } = getImageSize(backgroundImage, 'large') || {};
    const ThemeWrapper = getComponentFromName(ThemeComponents, name, null);
    return (
        <>
            <style type="text/css">{`
                #${id}.${containerClassName} {
                    ${primaryColor !== null ? `--article-primary-color: ${primaryColor};` : ''}
                    ${textColor !== null ? `--article-text-color: ${textColor};` : ''}
                    ${backgroundColor !== null && backgroundImageUrl !== null ? `--article-background: ${backgroundColor} url("${backgroundImageUrl}") top center repeat;` : ''}
                    ${backgroundColor !== null && backgroundImageUrl === null ? `--article-background: ${backgroundColor};` : ''}
                    ${backgroundImageUrl !== null && backgroundColor === null ? `--article-background: url("${backgroundImageUrl}") top center repeat;` : ''}
                }
            `}</style>
            {ThemeWrapper !== null ? (
                <ThemeWrapper id={id} {...props} ref={containerRef}>
                    {children}
                </ThemeWrapper>
            ) : (
                <div id={id} {...props} ref={containerRef}>
                    {children}
                </div>
            )}
        </>
    );
}

ArticleTheme.propTypes = propTypes;
ArticleTheme.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <ArticleTheme containerRef={ref} {...props} />);
