export function getParentElement(element, tagName, maxDepth = 10, ignoreClass = null, depth = 1) {
    const elementTagName = element.tagName || '';
    if (
        elementTagName.toLowerCase() === tagName.toLowerCase() &&
        (ignoreClass === null || !element.classList.contains(ignoreClass))
    ) {
        return element;
    }
    const parent = element.parentNode || null;
    return parent !== null && parent.tagName !== 'BODY' && depth < maxDepth
        ? getParentElement(parent, tagName, maxDepth, ignoreClass, depth + 1)
        : null;
}

export function isInElement(element, tagName, maxDepth = 10, ignoreClass = null, depth = 1) {
    return getParentElement(element, tagName, maxDepth, ignoreClass, depth) !== null;
}

export function isInInteractiveElement(element, maxDepth = 20, ignoreClass = null, depth = 1) {
    return (
        isInElement(element, 'canvas', maxDepth, ignoreClass, depth) ||
        isInElement(element, 'a', maxDepth, ignoreClass, depth) ||
        isInElement(element, 'button', maxDepth, ignoreClass, depth) ||
        isInElement(element, 'input', maxDepth, ignoreClass, depth) ||
        isInElement(element, 'textarea', maxDepth, ignoreClass, depth) ||
        isInElement(element, 'select', maxDepth, ignoreClass, depth) ||
        isInElement(element, 'form', maxDepth, ignoreClass, depth)
    );
}

export function isInInputElement(element, maxDepth = 20, ignoreClass = null, depth = 1) {
    return (
        isInElement(element, 'input', maxDepth, ignoreClass, depth) ||
        isInElement(element, 'textarea', maxDepth, ignoreClass, depth) ||
        isInElement(element, 'select', maxDepth, ignoreClass, depth)
    );
}

export default isInElement;
