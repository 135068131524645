import React from 'react';

export const BncFraude = React.lazy(
    () => import(/* webpackChunkName: "theme_bnc-fraude" */ './BncFraudeTheme'),
);

export const TourismeLaurentides = React.lazy(
    () => import(/* webpackChunkName: "theme_tourisme-laurentides" */ './TourismeLaurentidesTheme'),
);

export const Remax = React.lazy(() => import(/* webpackChunkName: "theme_remax" */ './RemaxTheme'));

export const JeuAideMachine = React.lazy(
    () => import(/* webpackChunkName: "theme_jeu-aide-machine" */ './JeuAideMachineTheme'),
);
