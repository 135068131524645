/* eslint-disable react/no-array-index-key */

/* eslint-disable react/jsx-props-no-spreading */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { usePopularDocuments } from '../../hooks/useDocuments';
import useUniqueDocumentsBlocks from '../../hooks/useUniqueDocumentsBlocks';
import * as AppPropTypes from '../../lib/PropTypes';
import { createLayoutRow, createVideosRow } from '../../lib/blocks';

import { useSite } from '../../contexts/SiteContext';
import UrbaniaHeader from '../headers/UrbaniaHeader';
import RowTitle from '../partials/RowTitle';
import HomePageBlocks from './HomePageBlocks';

import styles from '../../styles/pages/home-page-urbania.module.css';

const propTypes = {
    brand: AppPropTypes.brand.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};
function HomePageUrbania({ brand, className, ...props }) {
    const url = useUrlGenerator();
    const { handle: siteHandle } = useSite();
    const { sections = [] } = brand || {};
    const blocks = useUniqueDocumentsBlocks([
        {
            role: 'block',
            type: 'header_featured_list',
            brand,
            cardTheme: 'over-bottom-left',
            cardWithout: ['category'],
            count: 1,
            className: styles.featuredBlock,
            innerClassName: styles.featuredInner,
            cardClassName: styles.featuredCard,
            query: {
                brand: brand.id,
                exclude_type: ['contest', 'newsletter'],
                placement: ['home_featured_urbania'],
            },
            uniqueDocuments: true,
        },
        createLayoutRow('1_1_1_4', {
            cardTheme: 'box-translucent',
            title: (
                <RowTitle
                    url={url('all', {
                        brand: brand.slug,
                    })}
                >
                    <FormattedMessage
                        defaultMessage="Les plus récents"
                        description="Section title"
                    />
                </RowTitle>
            ),
            query: {
                brand: brand.id,
                placement: ['home_new_urbania', 'home_featured_urbania'],
                exclude_type: ['newsletter'],
                // type: ['article', 'video'],
            },
            cardWithout: ['brand-icon'],
            clickRef: 'home_new',
            loading: 'lazy',
            cardImageLoading: 'lazy',
            uniqueDocuments: true,
        }),
        createLayoutRow('1_1_1_4', {
            cardTheme: 'box-translucent',
            title: (
                <RowTitle
                    url={url('popular', {
                        brand: brand.slug,
                    })}
                >
                    <FormattedMessage
                        defaultMessage="Les plus populaires"
                        description="Section title"
                    />
                </RowTitle>
            ),
            query: {
                brand: brand.id,
                placement: ['home_popular_urbania', 'home_featured_urbania'],
                // popular: 'week',
            },
            hook: usePopularDocuments,
            clickRef: 'home_popular',
            loading: 'lazy',
            cardImageLoading: 'lazy',
            cardWithout: ['brand-icon'],
            uniqueDocuments: true,
        }),
        {
            role: 'block',
            type: 'header_featured_sidebar',
            presentation: 'row',
            itemsType: 'documents',
            title: <RowTitle url={url('micromags')}>Micromag URBANIA</RowTitle>,
            query: {
                type: 'micromag',
                collection: `micromag-${siteHandle}`,
            },
            loading: 'lazy',
            cardImageLoading: 'lazy',
            featuredCardType: 'featured-micromag',
            featuredCardTheme: 'left',
            featuredCardRatio: 'micromag',
            featuredCardWith: ['image-link'],
            featuredCardWithout: ['link'],
            cardType: 'over',
            cardSize: 'big',
            cardTheme: 'outline',
            cardRatio: 'micromag',
            responsive: true,
            cardWidth: 200,
            // count: 9,
            cardWithout: ['text', 'brand-icon', 'type-icon'],
            withBorderRadius: false,
            featuredCardClassName: styles.featuredMicromag,
        },
        createVideosRow({
            title: (
                <RowTitle url={url('videos')}>
                    <FormattedMessage
                        defaultMessage="Vidéos récentes"
                        description="Section title"
                    />
                </RowTitle>
            ),
            query: {
                brand: brand.id,
            },
            clickRef: 'home_videos',
            loading: 'lazy',
            cardImageLoading: 'lazy',
            cardWithout: ['category'],
            uniqueDocuments: true,
        }),
        {
            type: 'collections-row',
            presentation: 'grid',
            title: (
                <RowTitle url={url('videos')}>
                    <FormattedMessage defaultMessage="Séries vidéos" description="Section title" />
                </RowTitle>
            ),
            count: 6,
            query: {
                // brand: brand.id,
                handle: [
                    'courrier-recommande',
                    'entre-moi-et-moi',
                    'passes-croises',
                    'fragments',
                    'comment-faire-lamour',
                    'notre-dame-du-stand-up',
                ],
            },
            loading: 'lazy',
            cardImageLoading: 'lazy',
            cardType: 'vertical',
            cardRatio: 'vertical',
            cardTheme: 'box-translucent',
            cardWidth: 200,
            cardWithout: ['brand-icon'],
        },
        {
            type: 'collections-row',
            presentation: 'grid',
            minColumns: 1,
            maxColumns: 'auto',
            className: styles.collectionsRow,
            title: (
                <RowTitle url={url('podcasts')}>
                    <FormattedMessage defaultMessage="Balados" description="Section title" />
                </RowTitle>
            ),
            count: 3,
            query: {
                // brand: brand.id,
                handle: [
                    'balado-urbania',
                    'les-pires-moments-de-lhistoire',
                    'pas-nees-pour-un-petit-pain',
                    'histoires-de-mauvais-couts',
                ],
            },
            loading: 'lazy',
            cardImageLoading: 'lazy',
            cardType: 'documents',
            cardRatio: 'square',
            cardSize: null,
            cardTheme: 'box-translucent',
            cardWidth: 350,
            cardProps: {
                listTitle: (
                    <RowTitle>
                        <FormattedMessage
                            defaultMessage="Derniers épisodes"
                            description="Section title"
                        />
                    </RowTitle>
                ),
                listProps: {
                    loading: 'lazy',
                    cardImageLoading: 'lazy',
                    cardWithout: ['brand-icon', 'category', 'serie'],
                },
                listQuery: {
                    type: 'podcast',
                },
            },
            cardWithout: ['brand-icon', 'text'],
        },

        ...sections.map(({ id: sectionId, label, slug }) =>
            createLayoutRow('1_1_1_4', {
                role: 'block',
                title: (
                    <RowTitle url={url('section', { brand: brand.slug, section: slug })}>
                        {label}
                    </RowTitle>
                ),
                loading: 'lazy',
                query: {
                    brand: brand.id,
                    section: sectionId,
                },
                clickRef: `home_section_${slug}`,
                cardType: 'vertical',
                cardTheme: 'box-translucent',
                cardWithout: slug !== 'culture' ? ['brand-icon', 'category'] : ['brand-icon'],
                uniqueDocuments: slug !== 'actualites',
            }),
        ),
        {
            type: 'authors-row',
            presentation: 'grid',
            minColumns: 1,
            maxColumns: 'auto',
            className: styles.collectionsRow,
            title: <RowTitle>Dans l’univers URBANIA</RowTitle>,
            count: 4,
            query: {
                // brand: brand.id,
                handle: ['hugo-meunier', 'jean-bourbeau', 'benoit-lelievre', 'malia-kounkou'],
            },
            loading: 'lazy',
            cardImageLoading: 'lazy',
            cardType: 'documents',
            cardRatio: 'square',
            cardSize: 'medium',
            cardTheme: 'box-translucent',
            cardWidth: 300,
            cardProps: {
                listProps: {
                    loading: 'lazy',
                    cardImageLoading: 'lazy',
                    cardWithout: ['brand-icon'],
                },
                listCount: 4,
            },
            cardWithout: ['brand-icon', 'text'],
        },
        {
            type: 'brands-row',
            presentation: 'grid',
            minColumns: 1,
            maxColumns: 'auto',
            className: styles.collectionsRow,
            count: 4,
            query: {
                // brand: brand.id,
                handle: ['dehors', 'quatre95', 'mollo', 'periodelibre'],
            },
            loading: 'lazy',
            cardImageLoading: 'lazy',
            cardType: 'documents',
            cardRatio: 'square',
            cardSize: 'medium',
            cardTheme: 'box-translucent',
            cardWidth: 300,
            cardProps: {
                listProps: {
                    loading: 'lazy',
                    cardImageLoading: 'lazy',
                    cardWithout: ['brand-icon'],
                    hook: usePopularDocuments,
                },
                listCount: 5,
            },
            cardWithout: ['brand-icon', 'text'],
        },
    ]);
    return (
        <HomePageBlocks
            {...props}
            brand={brand}
            blocks={blocks}
            header={<UrbaniaHeader className={styles.header} />}
            className={classNames([styles.container, className])}
            blocksClassName={styles.blocks}
        />
    );
}

HomePageUrbania.propTypes = propTypes;
HomePageUrbania.defaultProps = defaultProps;

export default HomePageUrbania;
