import { useEffect, useState } from 'react';

export default function useTimelineVisibleRange(
    timelineIndex,
    { zoomedIn = false, siblingsVisible = false, transitioning = false, direction = null, maxIndex = 0 } = {},
) {
    const [range, setRange] = useState({
        start: timelineIndex,
        end: timelineIndex + (!zoomedIn && siblingsVisible ? 1 : 0),
    });
    useEffect(() => {
        if (!siblingsVisible) {
            return;
        }
        let visibleBefore = 0;
        if (!zoomedIn) {
            visibleBefore = 1;
        } else if (zoomedIn && transitioning && direction === -1) {
            visibleBefore = 1;
        }
        let visibleAfter = 0;
        if (!zoomedIn) {
            visibleAfter = 1;
        } else if (zoomedIn && transitioning && direction === 1) {
            visibleAfter = 1;
        }
        // const visibleAfter = !zoomedIn || transitioning ? 1 : 0;
        const newStart = Math.max(0, timelineIndex - visibleBefore);
        const newEnd = Math.min(maxIndex, timelineIndex + visibleAfter);
        setRange({
            start: newStart,
            end: newEnd,
        });
    }, [timelineIndex]);

    useEffect(() => {
        if (!siblingsVisible) {
            return;
        }
        const visibleBefore = 1;
        const visibleAfter = 1;
        const newStart = Math.max(0, timelineIndex - visibleBefore);
        const newEnd = Math.min(maxIndex, timelineIndex + visibleAfter);
        setRange({
            start: newStart,
            end: newEnd,
        });
    }, [siblingsVisible]);
    return range;
}
