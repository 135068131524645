/* eslint-disable react/jsx-props-no-spreading */
import { Ad as BaseAd, useAdsContext, useAdsTargeting } from '@folklore/ads';
import { useIsVisible } from '@folklore/hooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import AdWrapper from './AdWrapper';

import styles from '../../styles/ads/ad.module.css';

const propTypes = {
    title: PropTypes.node,
    withoutBorder: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    emptyClassName: PropTypes.string,
    loading: PropTypes.oneOf(['lazy', null]),
    onRender: PropTypes.func,
};

const defaultProps = {
    title: <FormattedMessage defaultMessage="Publicité" description="Ad title" />,
    withoutBorder: false,
    disabled: false,
    className: null,
    emptyClassName: null,
    onRender: null,
    loading: null,
};

function Ad({
    title,
    withoutBorder,
    loading,
    className,
    emptyClassName,
    onRender,
    disabled,
    ...props
}) {
    const { ads: adsManager } = useAdsContext();
    const [renderEvent, setRenderEvent] = useState(null);
    const { isEmpty = true, isFluid = false } = renderEvent || {};
    const [wrapper, setWrapper] = useState(null);
    const { ref: refVisible, visible: isVisible = false } = useIsVisible({
        disabled: loading !== 'lazy',
        persist: true,
    });
    // const {
    //     ad: parallax,
    //     setAd: setParallaxAd,
    //     style: parallaxAdStyle,
    //     adContainerRef,
    //     blurBackground,
    // } = useParallaxAd();
    // const isParallax = parallax !== null;

    const [lastRenderedSize, setLastRenderedSize] = useState(null);
    const onAdRender = useCallback(
        (event) => {
            setWrapper(null);
            setRenderEvent(event);
            const { isEmpty: newIsEmpty = true, width: newWidth, height: newHeight } = event || {};
            const isRendered = !newIsEmpty;

            setLastRenderedSize(
                isRendered
                    ? {
                          width: newWidth,
                          height: newHeight,
                      }
                    : null,
            );
            if (onRender !== null) {
                onRender(event);
            }
        },
        [onRender],
    );

    const onRichAd = useCallback(
        (richAd) => {
            const { type = null } = richAd || {};
            if (type === 'wrapper') {
                setWrapper(richAd);
            } else {
                setWrapper(null);
            }
        },
        [onRender],
    );

    const contextTargeting = useAdsTargeting();
    const { disabled: targetingDisabled = false } = contextTargeting || {};
    const finalDisabled = adsManager.isDisabled() || targetingDisabled || disabled;

    const isRendered = !isEmpty && !finalDisabled;
    const keepSize = lastRenderedSize !== null && !isRendered;

    return (
        <div
            className={classNames([
                styles.container,
                {
                    // [styles.isParallax]: isParallax,
                    [styles.isDisabled]: finalDisabled && !keepSize,
                    [styles.isRendered]: renderEvent !== null,
                    [styles.isFluid]: isFluid,
                    [styles.isEmpty]: isEmpty && renderEvent !== null && !keepSize,
                    [styles.hasWrapper]: wrapper !== null,
                    [styles.withoutBorder]: withoutBorder,
                    [emptyClassName]: emptyClassName !== null && isEmpty && renderEvent !== null,
                    [className]: className !== null,
                },
            ])}
            ref={refVisible}
        >
            <div className={styles.inner}>
                <div className={styles.title}>{title}</div>
                <AdWrapper
                    wrapper={wrapper}
                    className={styles.center}
                    innerClassName={styles.adContainer}
                    transformClassName={styles.adTransform}
                >
                    <BaseAd
                        {...props}
                        disabled={disabled || (loading === 'lazy' && !isVisible)}
                        // alwaysRender={alwaysRender || isVisible}
                        withReactId
                        shouldKeepSize
                        className={styles.ad}
                        richAdClassName={classNames([{ [styles.richAd]: wrapper === null }])}
                        richAdIframeClassName={classNames([
                            { [styles.richAdIframe]: wrapper === null },
                        ])}
                        onRender={onAdRender}
                        onRichAd={onRichAd}
                    />
                </AdWrapper>
            </div>
        </div>
    );
}

Ad.propTypes = propTypes;
Ad.defaultProps = defaultProps;

export default Ad;
