/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'wouter';

import BasicButton from '../buttons/BasicButton';

// import * as AppPropTypes from '../../lib/PropTypes';
import styles from '../../styles/cards/vertical-card.module.css';

const propTypes = {
    href: PropTypes.string,
    thumbnail: PropTypes.node,
    thumbnailRatio: PropTypes.number,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    children: PropTypes.node,
    footer: PropTypes.node,
    style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    className: PropTypes.string,
    thumbnailClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    innerClassName: PropTypes.string,
    footerClassName: PropTypes.string,
    labelOnTop: PropTypes.bool,
    onClick: PropTypes.func,
};

const defaultProps = {
    href: null,
    thumbnail: null,
    thumbnailRatio: null,
    width: null,
    height: null,
    children: null,
    footer: null,
    style: null,
    className: null,
    thumbnailClassName: null,
    labelClassName: null,
    innerClassName: null,
    footerClassName: null,
    labelOnTop: false,
    onClick: null,
};

function VerticalCard({
    href,
    thumbnail,
    thumbnailRatio,
    width,
    height,
    children,
    footer,
    style,
    className,
    thumbnailClassName,
    labelClassName,
    innerClassName,
    footerClassName,
    labelOnTop,
    onClick,
}) {
    const thumbnailStyle = {
        width: width || '100%',
        height: height || width / thumbnailRatio,
        paddingBottom:
            width === null && height === null && thumbnailRatio !== null
                ? `${(1 / thumbnailRatio) * 100}%`
                : null,
    };

    const Element = href !== null || onClick !== null ? 'span' : 'div';

    const inner = (
        <Element
            className={classNames([
                styles.inner,
                {
                    [innerClassName]: innerClassName !== null,
                },
            ])}
        >
            {labelOnTop ? (
                <Element
                    className={classNames([
                        styles.label,
                        {
                            [labelClassName]: labelClassName !== null,
                        },
                    ])}
                >
                    {children}
                </Element>
            ) : null}
            <Element
                className={classNames([
                    styles.thumbnail,
                    {
                        'contain-size': thumbnailStyle.paddingBottom !== null,
                        [thumbnailClassName]: thumbnailClassName !== null,
                    },
                ])}
                style={thumbnailStyle}
            >
                {thumbnail}
            </Element>
            {!labelOnTop ? (
                <Element
                    className={classNames([
                        styles.label,
                        {
                            [labelClassName]: labelClassName !== null,
                        },
                    ])}
                >
                    {children}
                </Element>
            ) : null}
            {footer !== null ? (
                <Element
                    className={classNames([
                        styles.footer,
                        {
                            [footerClassName]: footerClassName !== null,
                        },
                    ])}
                >
                    {footer}
                </Element>
            ) : null}
        </Element>
    );

    if (href !== null) {
        return (
            <Link
                href={href}
                className={classNames([
                    styles.container,
                    {
                        [className]: className !== null,
                    },
                ])}
                onClick={onClick}
                style={style}
            >
                {inner}
            </Link>
        );
    }

    if (onClick !== null) {
        return (
            <BasicButton
                className={classNames([
                    styles.container,
                    {
                        [className]: className !== null,
                    },
                ])}
                onClick={onClick}
                style={style}
            >
                {inner}
            </BasicButton>
        );
    }

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            style={style}
        >
            {inner}
        </div>
    );
}

VerticalCard.propTypes = propTypes;
VerticalCard.defaultProps = defaultProps;

export default VerticalCard;
