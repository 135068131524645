import { RoutesProvider } from '@folklore/routes';
import {
    useQuery,
    keepPreviousData as previousPlaceholder,
    useQueryClient,
} from '@tanstack/react-query';
import camelCase from 'lodash/camelCase';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';

import * as AppPropTypes from '../lib/PropTypes';
import createRoutes from '../lib/createRoutes';

import * as menuSections from '../data/menus';
import { useApi } from './ApiContext';

const STALE_TIME = 1000 * 60 * 30;

function udpateWithSite(queryClient, site) {
    const { handle: siteHandle, promotions = null } = site;
    if (promotions !== null) {
        queryClient.setQueryData(['promotions', siteHandle], promotions);
    }
    return {
        menu: {
            sections: menuSections[site.handle],
        },
        ...site,
        brands: site.brands.map((brand) => {
            const { videos } = brand;
            const newVideos = ['vertical', 'horizontal'].reduce((map, mode) => {
                const orientations = {
                    vertical: 'portrait',
                    horizontal: 'landscape',
                };
                const orientation = orientations[mode];
                const videoKeys = Object.keys(videos || {}).filter(
                    (key) =>
                        key.indexOf(camelCase(`bumper_${mode}`)) === 0 ||
                        key.indexOf(camelCase(`bumper_${orientation}`)) === 0,
                );
                const [lastOrientation = null] = Object.keys(map || {}) || [];
                const { name: lastVideoKey = null } =
                    lastOrientation !== null ? map[lastOrientation] || {} : {};
                const videoKeyFromLast =
                    lastVideoKey !== null
                        ? lastVideoKey.replace(
                              /^bumper(Portrait|Landscape)/,
                              camelCase(`bumper_${orientation}`),
                          )
                        : null;
                const videoKey =
                    videoKeyFromLast !== null && videoKeys.indexOf(videoKeyFromLast) !== -1
                        ? videoKeyFromLast
                        : videoKeys[Math.floor(Math.random() * videoKeys.length)];
                if (videoKey === null) {
                    return map;
                }
                return videoKey !== null
                    ? {
                          ...map,
                          [camelCase(`bumper_${orientation}`)]: {
                              name: videoKey,
                              ...videos[videoKey],
                          },
                      }
                    : map;
            }, null);
            return {
                ...brand,
                videos: {
                    ...videos,
                    ...newVideos,
                },
            };
        }),
    };
}

export default function useSiteQuery(handle, opts = null) {
    const api = useApi();
    const { keepPreviousData = true } = opts || {};
    const queryClient = useQueryClient();
    const { data: site, ...queryResult } = useQuery({
        queryKey: ['site', handle],
        queryFn: ({ queryKey: [, handleParam], signal }) =>
            api
                .site(handleParam, {
                    signal,
                })
                .then((newSite) => udpateWithSite(queryClient, newSite)),
        staleTime: STALE_TIME,
        suspense: false,
        placeholderData: keepPreviousData ? previousPlaceholder : null,
        ...opts,
    });

    return {
        site,
        ...queryResult,
    };
}

export function prefetchSite(queryClient, api, handle) {
    return queryClient.prefetchQuery({
        queryKey: ['site', handle],
        queryFn: ({ queryKey: [, handleParam], signal }) =>
            api
                .site(handleParam, {
                    signal,
                })
                .then((newSite) => udpateWithSite(queryClient, newSite)),
        staleTime: STALE_TIME,
    });
}

export function fetchSite(queryClient, api, handle) {
    return queryClient.fetchQuery({
        queryKey: ['site', handle],
        queryFn: ({ queryKey: [, handleParam], signal }) =>
            api
                .site(handleParam, {
                    signal,
                })
                .then((newSite) => udpateWithSite(queryClient, newSite)),
        staleTime: STALE_TIME,
    });
}

export const SiteContext = React.createContext({
    brands: [],
});

export function useSite() {
    const context = useContext(SiteContext);
    return context;
}

export function useIsRedirector() {
    const { isRedirector = false } = useSite();
    return isRedirector;
}

export function useMenu() {
    const { menu = null } = useSite();
    return menu;
}

export function useBrands() {
    const { brands } = useSite();
    return brands || [];
}

export function useAudiences() {
    const { audiences } = useSite();
    return audiences || [];
}

export function useSubscriptions() {
    const { subscriptions } = useSite();
    return subscriptions || [];
}

export function useBrand(slug, id) {
    const brands = useBrands() || [];
    return (
        brands.find(
            ({ slug: brandSlug, id: brandId, handle: brandHandle }) =>
                brandSlug === slug || brandId === id || brandId === slug || brandHandle === slug,
        ) || null
    );
}

export function useDefaultBrand() {
    const brands = useBrands() || [];
    return brands.find(({ default: isDefault = false }) => isDefault) || brands[0];
}

const propTypes = {
    handle: PropTypes.string,
    site: AppPropTypes.site,
    isRedirector: PropTypes.bool,
    children: PropTypes.node,
};

const defaultProps = {
    handle: null,
    site: null,
    isRedirector: false,
    children: null,
};

export function SiteContextProvider({ handle, site: providedSite, isRedirector, children }) {
    const { site: loadedSite = null } = useSiteQuery(handle, {
        enabled: providedSite === null,
    });
    const site = providedSite || loadedSite;

    const finalSite = useMemo(
        () =>
            site !== null
                ? {
                      ...site,
                      isRedirector,
                  }
                : null,
        [site, isRedirector],
    );

    const routes = useMemo(
        () => (finalSite !== null ? createRoutes(finalSite) : null),
        [finalSite],
    );

    if (finalSite === null) {
        return null;
    }
    return (
        <SiteContext.Provider value={finalSite}>
            <RoutesProvider routes={routes}>{children}</RoutesProvider>
        </SiteContext.Provider>
    );
}

SiteContextProvider.propTypes = propTypes;
SiteContextProvider.defaultProps = defaultProps;
