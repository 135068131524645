import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';

import FormButton from '../buttons/FormButton';
import TextField from '../fields/TextField';
import FormControl from '../forms/FormControl';
import FormRow from '../forms/FormRow';
import CardTitle from '../typography/CardTitle';
import PromotionDescription from '../typography/PromotionDescription';
import PromotionTitle from '../typography/PromotionTitle';
import Dialog from './Dialog';
import Modal from './Modal';

import styles from '../../styles/popups/delete-account-confirm-modal.module.css';

const propTypes = {
    className: PropTypes.string,
    onClosed: PropTypes.func,
    onConfirmed: PropTypes.func,
};

const defaultProps = {
    className: null,
    onClosed: null,
    onConfirmed: null,
};

function DeleteAccountConfirmModal({ className, onClosed, onConfirmed }) {
    const [opened, setOpened] = useState(true);
    const onClickCancel = useCallback(() => {
        setOpened(false);
    }, [setOpened]);
    const onRequestClose = useCallback(() => {
        setOpened(false);
    }, [setOpened]);
    const inputRef = useRef(null);
    const onOpened = useCallback(() => {
        if (inputRef.current !== null) {
            inputRef.current.focus();
        }
    }, []);
    const [confirmValue, setConfirmValue] = useState('');
    const [confirmError, setConfirmError] = useState(null);
    const onClickConfirm = useCallback(() => {
        if ((confirmValue || '').toLowerCase() !== 'oui') {
            setConfirmError('Veuillez entrer OUI pour confirmer');
            return;
        }
        if (onConfirmed !== null) {
            onConfirmed();
        }
    }, [confirmValue, onConfirmed]);
    return (
        <Modal
            id="delete_confirm"
            opened={opened}
            withBackdrop
            withTransition
            // withScrollLock
            className={classNames([styles.container, className])}
            onClosed={onClosed}
            onOpened={onOpened}
            onRequestClose={onRequestClose}
        >
            <Dialog>
                <PromotionTitle className={styles.title}>Supprimer votre compte</PromotionTitle>
                <PromotionDescription>
                    Êtes-vous certain de vouloir supprimer votre compte ?{' '}
                    <strong>Cette action est irréversible.</strong>
                </PromotionDescription>
                <FormControl
                    label="Entrez OUI dans le champ ci-dessous pour confirmer"
                    errors={confirmError !== null ? [confirmError] : null}
                    className={styles.control}
                >
                    <TextField
                        value={confirmValue}
                        errors={confirmError !== null ? [confirmError] : null}
                        onChange={setConfirmValue}
                    />
                </FormControl>
                <div className={styles.actions}>
                    <FormButton compact className={styles.button} onClick={onClickConfirm}>
                        Supprimer le compte
                    </FormButton>
                    <FormButton
                        compact
                        transparent
                        className={styles.button}
                        onClick={onClickCancel}
                    >
                        Annuler
                    </FormButton>
                </div>
            </Dialog>
        </Modal>
    );
}

DeleteAccountConfirmModal.propTypes = propTypes;
DeleteAccountConfirmModal.defaultProps = defaultProps;

export default DeleteAccountConfirmModal;
