/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable react/no-danger */

/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable no-nested-ternary, react/jsx-props-no-spreading, react/no-array-index-key */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useLocation } from 'wouter';

import { getParentElement } from '../../lib/isInElement';

import styles from '../../styles/documents/newsletter-document.module.css';

const propTypes = {
    id: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    html: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    id: null,
    html: null,
    className: null,
};

function NewsletterDocument({ id, html, className }) {
    const [, setLocation] = useLocation();
    const [, body = null] = (html || '').match(/<body[^>]*>([\s\S.]*)<\/body>/) || [];
    const [, style = null] = (html || '').match(/<style[^>]*>([\s\S.]*)<\/style>/) || [];
    const onClick = useCallback((event) => {
        const link = getParentElement(event.target, 'a');
        if (link !== null && link.href.indexOf('https://urbania.ca/') === 0) {
            event.preventDefault();
            setLocation(link.href.replace('https://urbania.ca', ''));
        }
    }, []);
    return (
        <div className={classNames([styles.container, className])} id={`newsletter-${id}`}>
            <style
                type="text/css"
                dangerouslySetInnerHTML={{
                    __html: `#newsletter-${id} { ${style || ''} }`,
                }}
            />
            <div
                dangerouslySetInnerHTML={{
                    __html: (body || '').replace(/<body/, '<div').replace(/<\/body>/, '</div>'),
                }}
                onClick={onClick}
            />
        </div>
    );
}

NewsletterDocument.propTypes = propTypes;
NewsletterDocument.defaultProps = defaultProps;

export default NewsletterDocument;
