/* eslint-disable react/no-array-index-key */

/* eslint-disable react/no-danger */
import { useRoutes } from '@folklore/routes';
import PropTypes from 'prop-types';
import React, { Suspense, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Route } from 'wouter';

import { useCurrentDocument } from '../hooks/useDocument';

import { useSite } from '../contexts/SiteContext';
import MetadataRoutes from './MetadataRoutes';
import PreloadBumper from './metadata/PreloadBumper';
import PreloadMicromag from './metadata/PreloadMicromag';

const propTypes = {
    assetsManifest: PropTypes.shape({
        files: PropTypes.objectOf(PropTypes.string).isRequired,
        entrypoints: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    embeddedStyles: PropTypes.string,
    embeddedScripts: PropTypes.string,
    tagManagerId: PropTypes.string,
    fontsToLoad: PropTypes.arrayOf(PropTypes.string),
    facebookAppId: PropTypes.string,
    children: PropTypes.node.isRequired,
};

const defaultProps = {
    embeddedStyles: null,
    embeddedScripts: null,
    tagManagerId: 'GTM-WBRBHVQ',
    fontsToLoad: [
        'static/media/Agrandir-WideBold.woff2',
        'static/media/Agrandir-TightRegular.woff2',
        'static/media/Agrandir-TightMedium.woff2',
        'static/media/GarageGothic.woff2',
    ],
    facebookAppId: null,
};

function Html({
    assetsManifest,
    embeddedStyles,
    embeddedScripts,
    tagManagerId,
    facebookAppId,
    fontsToLoad,
    children,
}) {
    const { handle: siteHandle } = useSite();
    const { locale } = useIntl();
    const { files: assetsFiles, entrypoints = [] } = assetsManifest;
    const routes = useRoutes();
    const { type: documentType = null, theme: documentTheme = null } = useCurrentDocument() || {};
    const { name: themeName = null } = documentTheme || {};

    const [loadedStylesheets, setLoadedStylesheets] = useState(
        [
            assetsFiles[`document_${documentType}.css`] || null,
            assetsFiles[`theme_${themeName}.css`] || null,
        ].filter((it) => it !== null),
    );
    const [loadedScripts, setLoadedScripts] = useState(
        [assetsFiles[`document_${documentType}.js`] || null].filter((it) => it !== null),
    );
    useEffect(() => {
        let newLoadedStylesheets = null;
        const typeStylesheet = assetsFiles[`document_${documentType}.css`] || null;
        if (typeStylesheet !== null && loadedStylesheets.indexOf(typeStylesheet) === -1) {
            newLoadedStylesheets = [...(newLoadedStylesheets || loadedStylesheets), typeStylesheet];
        }
        const themeStyleSheet = assetsFiles[`theme_${themeName}.css`] || null;
        if (themeStyleSheet !== null && loadedStylesheets.indexOf(themeStyleSheet) === -1) {
            newLoadedStylesheets = [
                ...(newLoadedStylesheets || loadedStylesheets),
                themeStyleSheet,
            ];
        }
        if (newLoadedStylesheets !== null) {
            setLoadedStylesheets(newLoadedStylesheets);
        }

        let newLoadedScripts = null;
        const typeScript = assetsFiles[`document_${documentType}.js`] || null;
        if (typeScript !== null && loadedScripts.indexOf(typeScript) === -1) {
            newLoadedScripts = [...(newLoadedScripts || loadedScripts), typeScript];
        }
        if (newLoadedScripts !== null) {
            setLoadedScripts(newLoadedScripts);
        }
    }, [documentType]);

    return (
        <html lang={locale}>
            <head>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                {facebookAppId !== null ? (
                    <meta property="fb:app_id" content={facebookAppId} />
                ) : null}
                <link rel="sitemap" type="application/xml" title="Sitemap" href="/sitemap.xml" />
                <link
                    rel="preconnect"
                    href={
                        siteHandle === 'france'
                            ? 'https://api.urbania.fr'
                            : 'https://api.urbania.ca'
                    }
                />
                <link rel="preconnect" href="https://cdn-v2.urbania.ca" />

                {embeddedStyles !== null ? (
                    <style
                        type="text/css"
                        id="embedded-styles"
                        dangerouslySetInnerHTML={{ __html: embeddedStyles }}
                    />
                ) : (
                    entrypoints
                        .filter((it) => it.match(/\.css/) !== null)
                        .map((file) => (
                            <link
                                rel="preload"
                                href={`/${file}`}
                                key={`preload-${file}`}
                                as="style"
                                crossOrigin="anonymous"
                            />
                        ))
                )}

                {embeddedScripts !== null ? (
                    <script
                        type="text/javascript"
                        id="embedded-scripts"
                        dangerouslySetInnerHTML={{
                            __html: embeddedScripts,
                        }}
                    />
                ) : null}

                {loadedStylesheets.map((file) => (
                    <link rel="stylesheet" href={file} type="text/css" crossOrigin="anonymous" />
                ))}

                {typeof assetsFiles[`document_${documentType}.js`] !== 'undefined' ? (
                    <link
                        rel="preload"
                        href={assetsFiles[`document_${documentType}.js`]}
                        as="script"
                        crossOrigin="anonymous"
                    />
                ) : null}

                <MetadataRoutes />

                <Route path={routes.micromag}>
                    <PreloadMicromag />
                </Route>
                <Route path={routes.home}>
                    <PreloadBumper />
                </Route>

                {fontsToLoad.map((font) =>
                    typeof assetsFiles[font] !== 'undefined' ? (
                        <link
                            rel="preload"
                            href={assetsFiles[font]}
                            as="font"
                            crossOrigin="anonymous"
                        />
                    ) : null,
                )}

                <link
                    rel="preload"
                    href="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
                    as="script"
                />
            </head>
            <body>
                <noscript>
                    <iframe
                        src={`https://www.googletagmanager.com/ns.html?id=${tagManagerId}`}
                        title="GTM"
                        height="0"
                        width="0"
                        style={{ display: 'none', visibility: 'hidden' }}
                    />
                </noscript>
                {children}
                {embeddedStyles === null
                    ? entrypoints
                          .filter((it) => it.match(/\.css/) !== null)
                          .map((file) => (
                              <link rel="stylesheet" href={`/${file}`} key={`stylesheet-${file}`} />
                          ))
                    : null}

                {loadedScripts.map((file) => (
                    <script type="text/javascript" src={file} />
                ))}
            </body>
        </html>
    );
}

Html.propTypes = propTypes;
Html.defaultProps = defaultProps;

export default Html;
