/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import Blocks from './Blocks';

import styles from '../../styles/blocks/section-block.module.css';

const propTypes = {
    blocks: AppPropTypes.blocks.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function SectionBlock({ blocks, className }) {
    return (
        <Blocks
            blocks={blocks}
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        />
    );
}

SectionBlock.propTypes = propTypes;
SectionBlock.defaultProps = defaultProps;

export default SectionBlock;
